import React from "react";

import css from "./header.module.css";

const Header = (props) => {
  const { headerText, descriptionText, handleSaveClick, handleDiscardClick } = props;
  return (
    <div className={css.header}>
      <div className={css.titleContainer}>
        <p className={css.headerText}>{headerText}</p>
        <p className={css.descriptionText}>{descriptionText}</p>
      </div>
    </div>
  )
}

export default Header;