import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./MoveRemoteConfig.module.css";

import MainSection from "../../pageComponents/createRemoteConfig/mainSection";
import Header from "../../pageComponents/createRemoteConfig/header";

import { API, ExperimentFormMode, ExperimentStatus, LocalStorageKey, WebsitePageLinks } from "../../services/constants";
import { makeGetAPICAll, makePostAPICAll } from "../../services/api";
import { getLocalStorage } from "../../services/localStorage";
import { getNextStage } from "../../utils/stage";

import { getMoveRemoteConfigRequest } from "../../model/experiment";
import PrimaryButton from "../../components/PrimaryButton";
import Snackbar from "../../components/Snackbar";
import SecondaryButton from "../../components/SecondaryButton";

const MoveRemoteConfig = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  const mode = ExperimentFormMode.move, currentStage = getLocalStorage(LocalStorageKey.stage);
  const headerText = "Move an Remote Config", descriptionText = "When moving an remote config, the system will automatically create variables and treatments. If you've created them manually, please delete them before proceeding with the move.";

  const [experimentDetails, setExperimentDetails] = useState({});
  const [formDetails, setFormDetails] = useState({ variants: [{}, {}] });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);


  useEffect(() => {
    if (params.id) {
      getExperimentById(params.id);
    }
  }, [params.id])

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const getExperimentById = (experimentId) => {
    makeGetAPICAll(API.getById(experimentId))
      .then(response => {
        if (response.success) {
          setExperimentDetails(response.data);
          setFormDetails(getFormDetails({ ...response.data }));
        } else {
          setResponseError(response?.data?.message);
        }
      })
      .catch(error => {
        setResponseError('Could not perform Requested Operation')
      })
  }

  const getFormDetails = (experimentDetails) => {
    delete experimentDetails.startDate; delete experimentDetails.endDate; //for move experiment they have to select start date and end date.
    return {
      ...experimentDetails,
      experimentVariableId: experimentDetails.variableId,
      status: ExperimentStatus.created,
      variants: experimentDetails.variants.map(variant => {
        return {
          ...variant,
          allowList: variant.allowedUsers
        }
      })
    }
  }

  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.experiment)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const saveFormData = () => {
    if (validateForm()) {
      setIsSaveClicked(true);
      makePostAPICAll(API.moveRemoteConfig, getMoveRemoteConfigRequest({ ...formDetails, sourceStage: currentStage, targetStage: getNextStage(currentStage) }))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.experiment)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        }).finally(() => {
          setIsSaveClicked(false);
        })
    }
  }

  const validateForm = () => {
    let errorMessage = "";
    for (let key in formValidationDetails) {
      if (formValidationDetails[key] === false) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} />
      <MainSection mode={mode} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked || [ExperimentFormMode.view].includes(mode)} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
    </div>
  )
}

export default MoveRemoteConfig;