import React, { useState, useEffect } from "react";

import css from "./InputNumber.module.css";

import { KeyEvents } from "../../services/constants";

export default function InputNumber(props) {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    validateInput(props.value);
  }, []);

  useEffect(() => {
    validateInput(props.value);
  }, [props.value, props.isRequired, props.minValue, props.maxValue]);

  const validateInput = (value) => {
    setErrorMessage("");
    props.isValid && props.isValid(true, props.keyName);
    if (props.isRequired) {
      if (!value && value !== 0) {
        let message = "Enter " + props.label;
        props.isValid && props.isValid(false, props.keyName);
        setErrorMessage(message);
        return;
      }
    }

    if (typeof props.minValue === "number") {
      if (Number(value) < Number(props.minValue)) {
        let message =
          "Minimum value of " + props.label + " is " + props.minValue;
        setErrorMessage(message);
        props.isValid && props.isValid(false, props.keyName);
        return;
      }
    }

    //#TODO: remove this typeof check and set message only here and move setErrorMessage outside of all if statements.
    if (typeof props.maxValue === "number") {
      if (Number(value) > Number(props.maxValue)) {
        let message =
          "Maximum value of " + props.label + " is " + props.maxValue;
        setErrorMessage(props.maxValueErrorMessage || message);
        props.isValid && props.isValid(false, props.keyName);
        return;
      }
    }

    if (props.pattern && !props.pattern.test(value)) {
      let message = "Please enter valid " + props.label;
      setErrorMessage(message);
      props.isValid && props.isValid(false, props.keyName);
      return;
    }
  };

  //when input change this method call
  const handleInputChange = (event) => {
    props.isValid && props.isValid(true, props.keyName);
    setErrorMessage("");
    validateInput(event.target.value);
    props.onInputChange(event);
  };

  const handleKeyDown = (event) => {
    if (event.key === KeyEvents.arrowDown || event.key === KeyEvents.arrowUp) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={`control ${css.inputContainer}`}>
        <input
          name={props.keyName}
          className={errorMessage ? "input is-danger" : "input"}
          type="number"
          value={props.value || props.value === 0 ? props.value : ""}
          onChange={(e) => handleInputChange(e)}
          min={props.minValue}
          disabled={props.disabled}
          max={props.maxValue}
          onWheel={(event) => event.currentTarget.blur()}
          onKeyDown={handleKeyDown}
          placeholder={props.placeholder}
        />
        <p className="help is-danger">{errorMessage}</p>
      </div>
    </>
  );
}
