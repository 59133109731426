import { getCookie, deleteCookie } from "./../utils/Cookies";
import {
  CookiesKey,
  LocalStorageKey,
  StatusCodeResult,
  WebsitePageLinks,
} from "./../services/constants";
import { getLocalStorage } from "./localStorage";

export function makePostAPICAll(url, data, dummyAuth, skipToken = false) {
  return new Promise((resolve, reject) => {
    let headerObj = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie(CookiesKey.adminToken)}`,
      Stage: getLocalStorage(LocalStorageKey.stage)
    };

    if (dummyAuth) {
      headerObj.Authorization = dummyAuth;
    }

    if (skipToken) {
      delete headerObj.Authorization;
    }

    fetch(url, {
      method: "POST",
      headers: headerObj,
      body: JSON.stringify(data),
      //credentials: 'include'
    })
      .then((stream) => {
        console.log(stream);
        return stream.json();
      })
      .then((apiResponse) => {
        validateResponse(apiResponse);
        return resolve(apiResponse);
      })
      .catch(reject);
  });
}

export function makePutAPICAll(url, data) {
  return new Promise((resolve, reject) => {
    let headerObj = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie(CookiesKey.adminToken)}`,
      Stage: getLocalStorage(LocalStorageKey.stage)
    };

    fetch(url, {
      method: "PUT",
      headers: headerObj,
      body: JSON.stringify(data),
      //credentials: 'include'
    })
      .then((stream) => {
        console.log(stream);
        return stream.json();
      })
      .then((apiResponse) => {
        validateResponse(apiResponse);
        return resolve(apiResponse);
      })
      .catch(reject);
  });
}

export function makeGetAPICAll(url, data, skipVersion = false) {
  console.log(url);
  return new Promise((resolve, reject) => {
    const headerObj = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie(CookiesKey.adminToken)}`,
      Stage: getLocalStorage(LocalStorageKey.stage)
    };

    if (skipVersion) {
      delete headerObj.version;
    }

    if (data) {
      url = new URL(url);
      url.search = new URLSearchParams(data).toString();
    }

    fetch(url, {
      method: "GET",
      headers: headerObj,
    })
      .then((stream) => {
        if (stream) {
          return stream.json();
        }
      })
      .then((apiResponse) => {
        validateResponse(apiResponse);
        return resolve(apiResponse);
      })
      .catch(reject);
  });
}

export function makeDeleteAPICall(url, data) {
  return new Promise((resolve, reject) => {
    const headerObj = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie(CookiesKey.adminToken)}`,
      Stage: getLocalStorage(LocalStorageKey.stage)
    };

    if (data) {
      url = new URL(url);
      url.search = new URLSearchParams(data).toString();
    }

    fetch(url, {
      method: "DELETE",
      headers: headerObj,
      body: JSON.stringify(data),
    })
      .then((stream) => {
        if (stream) {
          return stream.json();
        }
      })
      .then((apiResponse) => {
        validateResponse(apiResponse);
        return resolve(apiResponse);
      })
      .catch(reject);
  });
}

export function getBlob(url, data) {
  return new Promise((resolve, reject) => {
    var req = new XMLHttpRequest();
    req.open("POST", url, true);
    req.responseType = "blob";
    req.setRequestHeader("Accept", "application/json");
    req.setRequestHeader("Content-Type", "application/json");
    req.setRequestHeader(
      "Authorization",
      "Bearer " + getCookie(CookiesKey.adminToken)
    );
    req.onreadystatechange = function () {
      if (req.response !== null) {
        return resolve(req.response);
      }
    };
    req.send(JSON.stringify(data));
  });
}

const validateResponse = (response) => {
  checkAuthorization(response);
};

const checkAuthorization = (response) => {
  if (response && response.code === StatusCodeResult.invalidToken) {
    deleteCookie(CookiesKey.adminToken);
    setTimeout(() => {
      window.location.href = WebsitePageLinks.signIn;
    }, 500);
  }
}

