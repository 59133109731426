import React, { useEffect, useState } from "react";

import css from "./createExperimentVariableModal.module.css";

import lodash from "lodash";

import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import InputText from "../../components/InputText";
import StatusDropdown from "../../components/StatusDropdown";

import { API, ExperimentVariableValueTypes, TreatmentValueTypes } from "../../services/constants";
import { makePostAPICAll } from "../../services/api";

import { getCreateExperimentVariableRequest } from "../../model/experimentVariable";
import TextArea from "../../components/TextArea";
import { isJsonString } from "../../utils/Validator";

const CreateExperimentVariableModal = (props) => {
  const { onModalClose } = props;
  const valueTypeOptions = [{ text: "Select Value Type", value: "" }, { text: "String", value: TreatmentValueTypes.string }, { text: "JSON", value: TreatmentValueTypes.json }];

  const [formDetails, setFormDetails] = useState({});
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formValidationDetails, setFormValidationDetails] = useState({});

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const saveFormData = () => {
    if (checkValidation()) {
      makePostAPICAll(API.createExperimentVariable, getCreateExperimentVariableRequest(formDetails))
        .then(response => {
          if (response.success) {
            onModalClose(true)
          } else {
            setErrorMessage(response.data || response?.data?.message);
          }
        })
        .catch(error => {
          setErrorMessage('Could not perform Requested Operation')
        })
    }
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    if (event.target.value === ExperimentVariableValueTypes.control) {
      formDetails.constantValue = null;
    }
    if (event.target.name === "name") {
      formDetails.slug = getSlug(event.target.value);
    }
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const checkValidation = () => {
    let errorMessage = "";
    if (formDetails.schema) {
      if (!isJsonString(formDetails.schema)) {
        setErrorMessage("Invalid JSON for schema");
        return false;
      }
    }
    for (let key in formValidationDetails) {
      if (!formValidationDetails[key]) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    return !Boolean(errorMessage);
  }

  const getSlug = (name) => {
    return name.toLowerCase().replace(/ /g, '_');
  }

  return (
    <div className={css.container}>
      <div className={css.header}>Create Experiment Variable</div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Name<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "name", "")}
              keyName="name"
              label="Name"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Name"
              isRequired={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Value Type<span className={css.required}>*</span></label>
          </div>
          <div>
            <StatusDropdown
              options={valueTypeOptions}
              onInputChange={handleInputChange}
              value={lodash.get(formDetails, "valueType", "")}
              keyName="valueType"
              required={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>App Version</label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "appVersion", "")}
              keyName="appVersion"
              label="App Version"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="App Version"
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Slug(For App):</label>
          </div>
          <div>
            <label className={css.slugLabel}>{formDetails.slug}</label>
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Schema</label>
          </div>
          <div>
            <TextArea
              value={lodash.get(formDetails, "schema", "")}
              keyName="schema"
              label="Schema"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Schema"
              disabled={formDetails.valueType === TreatmentValueTypes.string}
              rows={5}
            />
          </div>
        </div>
      </div>
      <p className={css.errorMessage}>{errorMessage}</p>
      <div className={css.footer}>
        <div className={css.buttonContainer}>
          <SecondaryButton text={"Discard"} onClick={() => onModalClose(false)} />
        </div>
        <div className={css.buttonContainer}>
          <PrimaryButton text="Save" onClick={handleSaveClick} />
        </div>
      </div>
    </div>
  )
}

export default CreateExperimentVariableModal;