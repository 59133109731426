import React, { useState } from "react";

import css from "./mainSection.module.css";

import lodash from "lodash";

import InputText from "../../../components/InputText";
import DatePicker from "./../../../components/DatePicker";
import TimePicker from "./../../../components/TimePicker";
import TextArea from "../../../components/TextArea";
import InputNumber from "../../../components/InputNumber";
import StatusDropdown from "../../../components/StatusDropdown";
import ExperimentVariableDropdown from "../../../components/ExperimentVariableDropdown";
import CreateExperimentVariableModal from "../../createExperimentVariableModal";
import Modal from "../../../components/Modal";
import TreatmentValueDropdown from "../../../components/TreatmentValueDropdown";

import { DateFormats, ExperimentFormMode, ExperimentStatus, TimeFormats } from "../../../services/constants";
import moment from "moment";

const MainSection = (props) => {
  const { mode } = props;
  const [shouldShowExperimentVariableModal, setShouldShowExperimentVariableModal] = useState(false);
  const [shouldRefreshExperimentVariable, setShouldRefreshExperimentVariable] = useState(false);

  const { formDetails, handleInputChange, shouldApplyValidation, handleIsValid } = props;

  const audienceOptions = [{ text: "Select Audience", value: "" }, { text: "All Users", value: "ALL_USERS" }]

  const handleModalClose = (isSuccess) => {
    setShouldShowExperimentVariableModal(false);
    if (isSuccess) {
      setShouldRefreshExperimentVariable(isSuccess);
    }
  }

  const handleCreateExperimentClick = () => {
    setShouldRefreshExperimentVariable(false);
    setShouldShowExperimentVariableModal(true);
  }

  return (
    <div className={css.mainSectionContainer}>
      <div className={css.descriptionText}>
        Main Section
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Name<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "name", "")}
              keyName="name"
              label="Name"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Name"
              isRequired={shouldApplyValidation}
              disabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Description<span className={css.required}>*</span></label>
          </div>
          <div>
            <TextArea
              value={lodash.get(formDetails, "description", "")}
              keyName="description"
              type="description"
              label="description"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="description"
              isRequired={shouldApplyValidation}
              disabled={[ExperimentFormMode.view].includes(mode)}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              rows={2}
            />
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Start Date<span className={css.required}>*</span></label>
          </div>
          <div>
            <DatePicker
              keyName="startDate"
              label="Start Date"
              onInputChange={(e) => handleInputChange(e)}
              date={formDetails.startDate}
              format={DateFormats.inputDate}
              isRequired={shouldApplyValidation}
              isValid={(isValid, property) =>
                handleIsValid(isValid, property)
              }
              isDisabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
              minDate={shouldApplyValidation && moment().format(DateFormats.inputDate)}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Start Time<span className={css.required}>*</span></label>
          </div>
          <div className={css.field}>
            <TimePicker
              keyName="startTime"
              label="Start Time"
              onInputChange={(e) => handleInputChange(e)}
              time={formDetails.startTime}
              step="300"
              format={TimeFormats.inputTime}
              isRequired={shouldApplyValidation}
              isDisabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
              isValid={(isValid, property) =>
                handleIsValid(isValid, property)
              }
            />
          </div>
        </div>
      </div>
      {/* <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>End Date</label>
          </div>
          <div>
            <DatePicker
              keyName="endDate"
              label="End Date"
              onInputChange={(e) => handleInputChange(e)}
              date={formDetails.endDate}
              format={DateFormats.inputDate}
              isDisabled={[ExperimentFormMode.view].includes(mode)}
              minDate={shouldApplyValidation && formDetails.endDate ? formDetails.startDate : null}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>End Time</label>
          </div>
          <div className={css.field}>
            <TimePicker
              keyName="endTime"
              label="End Time"
              onInputChange={(e) => handleInputChange(e)}
              time={formDetails.endTime}
              step="300"
              format={TimeFormats.inputTime}
              isRequired={shouldApplyValidation && formDetails.endDate}
              isValid={(isValid, property) =>
                handleIsValid(isValid, property)
              }
              isDisabled={[ExperimentFormMode.view].includes(mode)}
              min={shouldApplyValidation && formDetails.endDate === formDetails.startDate && formDetails.startTime}
            />
          </div>
        </div>

      </div> */}
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Ramp(%)<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputNumber
              value={lodash.get(formDetails, "ramp", "")}
              keyName="ramp"
              label="Ramp"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Ramp"
              isRequired={shouldApplyValidation}
              disabled={[ExperimentFormMode.view].includes(mode)}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              maxValue={100}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Variable<span className={css.required}>*</span></label>
          </div>
          <div>
            <ExperimentVariableDropdown
              onInputChange={handleInputChange}
              value={lodash.get(formDetails, "experimentVariableId", "")}
              keyName="experimentVariableId"
              shouldRefresh={shouldRefreshExperimentVariable}
              required={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              onCreateExperimentClick={handleCreateExperimentClick}
              disabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
            />
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Non Ramp User Treatment</label>
          </div>
          <div>
            <TreatmentValueDropdown
              onInputChange={(e) => handleInputChange(e, 0)}
              value={lodash.get(formDetails, "nonRampUserTreatmentValueId", "")}
              keyName="nonRampUserTreatmentValueId"
              required={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              disabled={[ExperimentFormMode.view].includes(mode)}
              variableId={formDetails.experimentVariableId}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Audience<span className={css.required}>*</span></label>
          </div>
          <div>
            <StatusDropdown
              options={audienceOptions}
              onInputChange={handleInputChange}
              value={lodash.get(formDetails, "audience", "")}
              keyName="audience"
              disabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
            />
          </div>
        </div>
      </div>

      {shouldShowExperimentVariableModal && (
        <Modal
          isModalOpen={shouldShowExperimentVariableModal}
          handleClose={(event) => handleModalClose(false)}
        >
          <CreateExperimentVariableModal
            onModalClose={(isSuccess) => handleModalClose(isSuccess)}
          />
        </Modal>
      )}
    </div>
  )
}

export default MainSection;