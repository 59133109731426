import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import css from "./CreateRemoteConfig.module.css";

import Header from "../../pageComponents/createRemoteConfig/header";
import MainSection from "../../pageComponents/createRemoteConfig/mainSection";

import Snackbar from "../../components/Snackbar";

import { API, ExperimentFormMode, WebsitePageLinks } from "../../services/constants";
import { makePostAPICAll } from "../../services/api";

import { getCreateRemoteConfigRequest } from "../../model/experiment";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";

const CreateRemoteConfig = (props) => {
  let navigate = useNavigate();
  const headerText = "Add a Remote Config";
  const descriptionText = "Configure your app on the fly with our powerful remote config feature.";

  const [formDetails, setFormDetails] = useState({ audience: "ALL_USERS", ramp: 100 });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);


  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.experiment)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const saveFormData = () => {
    if (validateForm()) {
      setIsSaveClicked(true);
      makePostAPICAll(API.createRemoteConfig, getCreateRemoteConfigRequest(formDetails))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.experiment)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        })
        .finally(() => {
          setIsSaveClicked(false);
        })
    }
  }

  const validateForm = () => {
    let errorMessage = "";
    for (let key in formValidationDetails) {
      if (formValidationDetails[key] === false) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} handleSaveClick={handleSaveClick} handleDiscardClick={handleDiscardClick} />
      <MainSection mode={ExperimentFormMode.create} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
    </div>
  )
}

export default CreateRemoteConfig;