import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from "../../context/userContext"; 
import { WebsitePageLinks } from "../../services/constants";

export default function PrivateRoute(props) {
  const { permissionNeeded, checkAdmin, ...options } = props;
  let { userData } = useUser();
  let canAccess = true;
  if(checkAdmin) {
    canAccess = !userData || userData.isAdmin;
  }
  return canAccess ? (
    <Outlet />
  ) : (
    <Navigate to={WebsitePageLinks.pageNotFound} />
  );
}
