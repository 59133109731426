import {
    _BASE_URL,
} from "./env.js";

export const defaultMinDate = "2023-09-01"
export const BASE_URL = _BASE_URL;
export const CookiesKey = {
    adminToken: "__EXPERIMENT_ADMIN_TOKEN__",
};

export const LocalStorageKey = {
    stage: "STAGE",
};

export const WebsitePageLinks = {
    signIn: "/sign-in",
    dashboard: "/dashboard",
    createExperiment: "/experiment/create",
    updateExperiment: "/experiment/update",
    viewExperiment: "/experiment/view",
    moveExperiment: "/experiment/move",
    createRemoteConfig: "/experiment/create-remote-config",
    updateRemoteConfig: "/experiment/update-remote-config",
    viewRemoteConfig: "/experiment/view-remote-config",
    moveRemoteConfig: "/experiment/move-remote-config",
    experiment: "/experiment",
    createExperimentVariable: "/create-experiment-variable",
    experimentVariable: "/experiment-variable",
    createTreatmentValue: "/create-treatment-value",
    treatmentValue: "/treatment-value",
    user: "/user",
    pageNotFound: "/page-not-found",
    root: "/",
}

export const WebsitePages = {
    signIn: {
        title: "Sign In",
        path: WebsitePageLinks.signIn,
    },
    dashboard: {
        title: "Dashboard",
        path: WebsitePageLinks.dashboard,
    },
    experimentVariable: {
        title: " Experiment Variable",
        path: WebsitePageLinks.experimentVariable,
    },
    treatmentValue: {
        title: " Treatment Value",
        path: WebsitePageLinks.treatmentValue,
    },
    experiment: {
        title: "Experiment",
        path: WebsitePageLinks.experiment,
    },
    createExperiment: {
        title: "Create Experiment",
        path: WebsitePageLinks.createExperiment,
    },
    updateExperiment: {
        title: "Update Experiment",
        path: WebsitePageLinks.updateExperiment,
    },
    viewExperiment: {
        title: "View Experiment",
        path: WebsitePageLinks.viewExperiment,
    },
    moveExperiment: {
        title: "Move Experiment",
        path: WebsitePageLinks.moveExperiment,
    },
    createRemoteConfig: {
        title: "Create Remote Config",
        path: WebsitePageLinks.createRemoteConfig,
    },
    updateRemoteConfig: {
        title: "Update Remote Config",
        path: WebsitePageLinks.updateRemoteConfig,
    },
    viewRemoteConfig: {
        title: "View Remote Config",
        path: WebsitePageLinks.viewRemoteConfig,
    },
    moveRemoteConfig: {
        title: "Move Remote Config",
        path: WebsitePageLinks.moveRemoteConfig,
    },
    createExperimentVariable: {
        title: "Create Experiment Variable",
        path: WebsitePageLinks.createExperimentVariable,
    },
    createExperimentTreatment: {
        title: "Create Treatment Value",
        path: WebsitePageLinks.createTreatmentValue,
    },
    user: {
        title: "User",
        path: WebsitePageLinks.user,
    },
    root: {
        title: "Dashboard",
        path: WebsitePageLinks.root,
    },
}

export const Validation = {
    allowedNumbers: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    emailReg: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
    urlReg: /^(ftp|http|https):\/\/[^ "]+$/,
    mobileNumberReg: /^[6-9]\d{9}$/,
    pinCodeReg: /^[1-9][0-9]{5}$/,
    alphaNumericReg: /^[\w\s]*$/,
    alphabetsWithSpaceReg: /^[a-zA-Z ]*$/,
    positiveInteger: /^[0-9]*$/,

}

export const API = {
    signIn: BASE_URL + "/auth/login",
    
    getExperimentVariables: BASE_URL + "/experiment-variable",
    createExperimentVariable: BASE_URL + "/experiment-variable",
    deleteExperimentVariable: BASE_URL + "/experiment-variable",
    updateExperimentVariable: (id) => BASE_URL + "/experiment-variable/" + id,

    getTreatmentValue: BASE_URL + "/experiment-treatment-value",
    createTreatmentValue: BASE_URL + "/experiment-treatment-value",
    deleteTreatmentValue: BASE_URL + "/experiment-treatment-value",
    updateTreatmentValue: (id) => BASE_URL + "/experiment-treatment-value/" + id,

    getExperiment: BASE_URL + "/experiment",
    getById: (id) => BASE_URL + "/experiment/" + id,
    createExperiment: BASE_URL + "/experiment",
    deleteExperiment: BASE_URL + "/experiment",
    updateExperiment: (id) => BASE_URL + "/experiment/" + id,
    createRemoteConfig: BASE_URL + "/experiment/remote-config",
    pauseExperiment: BASE_URL + "/experiment/pause",
    moveExperiment: BASE_URL + "/experiment/move",
    moveRemoteConfig: BASE_URL + "/experiment/move-remote-config",

    getUser: BASE_URL + "/user",
    getUsers: BASE_URL + "/user/all",
    createUser: BASE_URL + "/user",
    updateUser: (id) => BASE_URL + "/user/" + id,
    deleteUser: BASE_URL + "/user",
}

export const StatusCodeResult = {
    success: 200,
    invalidToken: 401,
    resetContent: 205,
};

export const Colors = {
    rating: "#ffd700",
    inputLabel: "#B3B3B3",
    errorBorder: "#EB5757",
    inputBorder: "#BDBDBD",
    progressBar: "#828282",
    backgroundPrimary: "#FADED6",
    backgroundSecondary: "#fff9f8",
    primary: "#1667D9",
    notOkay: "#e76250",
    good: "#5de747",
    average: "#ffc107",
    notApplicable: "#c4c4c4",
    absent: "#000000",
    active: "#3a5dd9",
    error: "#E76250",
    tooltip: "#eeeeee",
    create: "#56ccf2",
    paid: "#219653",
    expired: "#eb5757",
    buttonPrimary: "#5ac9fe",
    publishedAssessment: "#45bd32",
    black: "#000000",
    white: "#ffffff",
    publishedEvent: "#4CD964",
    nonPublishedEvent: "#C8C7CC",
    publishedCampaignBorder: "#F1F4F8",
    unPublishedCampaignBorder: "#d1d1d1"
};

export const DateFormats = {
    primary: "DD-MMM-YYYY",
    secondary: "DD/MM/YYYY",
    inputDate: "YYYY-MM-DD",
    modern: "DD MMM YYYY",
    short: "DD MMM"
};

export const TimeFormats = {
    primary: "HH:mm:ss",
    secondary: "h:mm A",
    inputTime: "HH:mm",
};

export const DateTimeFormats = {
    primary: "DD-MMM-YYYY HH:mm:ss",
    secondary: "DD-MM-YYYY HH:mm",
    tertiary: "DD/MM/YYYY HH:mm"
};

export const KeyEvents = {
    arrowDown: "ArrowDown",
    arrowUp: "ArrowUp",
    enter: "Enter"
};

export const ExperimentVariableValueTypes = {
    control: "CONTROL",
    constant: "CONSTANT"
}

export const TreatmentValueTypes = {
    json: "JSON",
    string: "STRING",
}

export const ExperimentStatus = {
    created: "CREATED",
    started: "STARTED",
    completed: "COMPLETED",
    paused: "PAUSED",
}

export const VariantTypes = {
    control: "CONTROL",
    treatment: "TREATMENT",
}

export const ExperimentFormMode = {
    view: "VIEW",
    edit: "EDIT",
    create: "CREATE",
    move: "MOVE",
}

export const Stage = {
    development: "DEVELOPMENT",
    staging: "STAGING",
    production: "PRODUCTION"
} 