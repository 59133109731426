import React from "react";

import css from "./treatmentValueTable.module.css";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import _ from "underscore";
import AccessChecker from "../../components/AccessChecker";

const TreatmentValueTable = (props) => {
  const { treatmentValues } = props;

  const onEditTreatment = (treatmentValue) => {
    props.onEditClick(treatmentValue);
  };

  const onDeleteTreatment = (treatmentValue) => {
    props.onDeleteClick(treatmentValue);
  };

  return (
    <div className={css.tableContainer}>
      <table className="table is-striped is-fullwidth">
        <thead className={css.tableHeaderContainer}>
          <tr>
            <th className={css.tableHeader}>Name</th>
            <th className={css.tableHeader}>Variable Name</th>
            <th className={css.tableHeader}>Value</th>
            <th className={css.tableHeader}>Experiment Name</th>
            <th className={css.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(treatmentValues) &&
            treatmentValues.map((treatmentValue, index) => {
              return (
                <tr key={index}>
                  <td className={css.tableData}>{treatmentValue.name}</td>
                  <td className={css.tableData}>{treatmentValue.variableName}</td>
                  <td className={css.valueData}>{treatmentValue.value ?? "-"}</td>
                  <td className={css.tableData}>{treatmentValue.experimentName ?? "-"}</td>
                  <td className={css.tableData}>
                    <div className={css.buttonContainer}>
                      <AccessChecker onClick={() => onEditTreatment(treatmentValue)}>
                        <div className={css.button}>
                          <EditIcon />
                        </div>
                      </AccessChecker>
                      <AccessChecker onClick={() => onDeleteTreatment(treatmentValue)}>
                        <div className={css.button}>
                          <DeleteIcon />
                        </div>
                      </AccessChecker>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TreatmentValueTable;
