import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import css from "./ViewRemoteConfig.module.css";

import MainSection from "../../pageComponents/createRemoteConfig/mainSection";
import Header from "../../pageComponents/createRemoteConfig/header";

import Snackbar from "../../components/Snackbar";
import SecondaryButton from "../../components/SecondaryButton";

import { API, ExperimentFormMode, WebsitePageLinks } from "../../services/constants";
import { makeGetAPICAll } from "../../services/api";

const ViewRemoteConfig = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  const mode = location.pathname.includes(WebsitePageLinks.updateRemoteConfig) ? ExperimentFormMode.edit : ExperimentFormMode.view;
  const headerText = mode === ExperimentFormMode.edit ? "Edit a Remote Config" : "View a Remote Config", descriptionText = "Configure your app on the fly with our powerful remote config feature.";

  const [experimentDetails, setExperimentDetails] = useState({});
  const [formDetails, setFormDetails] = useState({ variants: [{}, {}] });
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");

  useEffect(() => {
    if (params.id) {
      getExperimentById(params.id);
    }
  }, [params.id])


  const getExperimentById = (experimentId) => {
    makeGetAPICAll(API.getById(experimentId))
      .then(response => {
        if (response.success) {
          setExperimentDetails(response.data);
          setFormDetails(getFormDetails(response.data));
        } else {
          setResponseError(response?.data?.message);
        }
      })
      .catch(error => {
        setResponseError('Could not perform Requested Operation')
      })
  }

  const getFormDetails = (experimentDetails) => {
    return {
      ...experimentDetails,
      experimentVariableId: experimentDetails.variableId,
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.experiment)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} />
      <MainSection mode={mode} formDetails={formDetails} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
    </div>
  )
}

export default ViewRemoteConfig;