import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import css from "./Experiment.module.css";

import _ from "underscore";
import { CircularProgress } from '@mui/material';

import { makeDeleteAPICall, makeGetAPICAll, makePostAPICAll } from "../../services/api";
import { API, ExperimentStatus, LocalStorageKey, WebsitePageLinks } from "../../services/constants";
import { useUser } from "../../context/userContext";

import ExperimentTable from "../../pageComponents/experimentTable";

import PrimaryButton from "../../components/PrimaryButton";
import AlertDialog from "../../components/AlertDialog";
import AccessChecker from "../../components/AccessChecker";
import Snackbar from "./../../components/Snackbar";
import { getNextStage } from "../../utils/stage";
import { getLocalStorage } from "../../services/localStorage";

const Experiments = (props) => {
  const navigate = useNavigate();
  const { userData } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [experiments, setExperiments] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [selectedExperiment, setSelectedExperiment] = useState({});
  const [alertDialogDetails, setAlertDialogDetails] = useState({ shouldShow: false, title: '', handler: null });


  useEffect(() => {
    getExperiments();
  }, []);

  const getExperiments = (filters = { pageNumber: 1 }) => {
    setIsLoading(true);
    makeGetAPICAll(API.getExperiment, filters).then((response) => {
      setIsLoading(false);
      if (response.success) {
        setExperiments(response.data);
      } else {
        setResponseMessage(response.data || response.message);
      }
    });
  };

  const hanldeDeleteExperiment = (experimentId) => {
    selectedExperiment.id = experimentId;
    setSelectedExperiment({ ...selectedExperiment });
    setAlertDialogDetails({ shouldShow: true, header: "Are you sure you want to delete this experiment?", handler: handleDeleteExperimentModalClose })
  }

  const handlePauseExperiment = (experimentId, status) => {
    selectedExperiment.id = experimentId;
    setSelectedExperiment({ ...selectedExperiment });
    setAlertDialogDetails({ shouldShow: true, header: "Are you sure you want to " + (status === ExperimentStatus.paused ? "resume" : "pause") + " assignment to this experiment?", handler: handlePauseExperimentModalClose })
  }

  const handlePauseExperimentModalClose = (isSuccess) => {
    setAlertDialogDetails({ shouldShow: false, header: "", handler: null })
    if (isSuccess === true) {
      pauseExperiment();
    }
  }

  const handleDeleteExperimentModalClose = (isSuccess) => {
    setAlertDialogDetails({ shouldShow: false, header: "", handler: null })
    if (isSuccess === true) {
      deleteExperiment();
    }
  }

  const pauseExperiment = () => {
    setIsLoading(true);
    makePostAPICAll(API.pauseExperiment, { experimentId: selectedExperiment.id }).then((response) => {
      setIsLoading(false);
      if (response.success) {
        getExperiments();
      } else {
        setResponseMessage(response?.data?.message ?? "Error");
      }
    });
  }

  const deleteExperiment = () => {
    setIsLoading(true);
    makeDeleteAPICall(API.deleteExperiment, { id: selectedExperiment.id }).then((response) => {
      setIsLoading(false);
      if (response.success) {
        getExperiments();
      } else {
        setResponseMessage(response?.data?.message ?? "Error");
      }
    });
  }

  const handleViewClick = (experimentId, isRemoteConfig) => {
    let path = WebsitePageLinks.viewExperiment + "/" + experimentId;
    if(isRemoteConfig)
      path = WebsitePageLinks.viewRemoteConfig + "/" + experimentId;
    navigate(path);
  }

  const handleEditClick = (experimentId) => {
    navigate(WebsitePageLinks.updateExperiment + "/" + experimentId);
  }

  const handleMoveExperimentClick = (experiment) => {
    let grant = "EDIT_" + getNextStage(getLocalStorage(LocalStorageKey.stage));
    if(userData.access.includes(grant)) {
      let path = WebsitePageLinks.moveExperiment + "/" + experiment.id;
      if(experiment.isRemoteConfig)
        path = WebsitePageLinks.moveRemoteConfig + "/" + experiment.id
      navigate(path);
    } else {
      setResponseMessage("The account you signed in with does not have permission to perform this action");
    }
  }


  return (
    <div className={css.experimentsContainer}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className={css.header}>
            <div className={css.headerText}>Experiments</div>
            <div className={css.container}>
              <div className={css.buttonContainer}>
                <AccessChecker onClick={() => navigate(WebsitePageLinks.createExperiment)}>
                  <PrimaryButton text="Create Experiment" />
                </AccessChecker>
              </div>
              <div className={css.buttonContainer}>
                <AccessChecker onClick={() => navigate(WebsitePageLinks.createRemoteConfig)}>
                  <PrimaryButton text="Create Remote Config" />
                </AccessChecker>
              </div>
            </div>
          </div>
          <div className={css.tableContainer}>
            <ExperimentTable
              experiments={experiments}
              onViewClick={(experimentId, isRemoteConfig) => handleViewClick(experimentId, isRemoteConfig)}
              onEditExperiment={(experimentId) => handleEditClick(experimentId)}
              onDeleteExperiment={(experimentId) => hanldeDeleteExperiment(experimentId)}
              onPauseExperiment={(experimentId, status) => handlePauseExperiment(experimentId, status)}
              onMoveExperimentClick={(experiment) =>  handleMoveExperimentClick(experiment)}
            />
          </div>
        </>
      )}
      {alertDialogDetails.shouldShow && (
        <AlertDialog
          showAlert={alertDialogDetails.shouldShow}
          onDialogActionButtonClick={(dialogAction) =>
            alertDialogDetails.handler(dialogAction)
          }
          title={alertDialogDetails.header}
          disagreeText="No"
          agreeText="Yes"
        />
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default Experiments;