import React, { useEffect, useState } from "react";

import css from "./updateUserModal.module.css";

import lodash from "lodash";

import Checkbox from '@mui/material/Checkbox';

import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import InputText from "../../components/InputText";

import { API } from "../../services/constants";
import { makePutAPICAll } from "../../services/api";

import { updateUserRequest } from "../../model/user";
import AppGrants from "../../services/appGrants";

const UpdateUserModal = (props) => {
  const { onModalClose, userDetails } = props;

  const [formDetails, setFormDetails] = useState({ isAdmin: false, isDevEdit: false, isDevView: false, isStageEdit: false, isStageView: false, isProdEdit: false, isProdView: false });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formValidationDetails, setFormValidationDetails] = useState({});

  const updateFormDetails = () => {
    userDetails.grants = lodash.get(userDetails, "access", null) ? userDetails.access.split(",") : [];
    userDetails.isDevView = userDetails.grants.includes(AppGrants.VIEW_DEVELOPMENT);
    userDetails.isDevEdit = userDetails.grants.includes(AppGrants.EDIT_DEVELOPMENT);
    userDetails.isStageView = userDetails.grants.includes(AppGrants.VIEW_STAGING);
    userDetails.isStageEdit = userDetails.grants.includes(AppGrants.EDIT_STAGING);
    userDetails.isProdView = userDetails.grants.includes(AppGrants.VIEW_PRODUCTION);
    userDetails.isProdEdit = userDetails.grants.includes(AppGrants.EDIT_PRODUCTION);
    setFormDetails({ ...userDetails });
  }

  useState(() => {
    updateFormDetails();
  }, [])

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);



  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const saveFormData = () => {
    if (checkValidation()) {
      makePutAPICAll(API.updateUser(userDetails.id), updateUserRequest(formDetails))
        .then(response => {
          if (response.success) {
            onModalClose(true)
          } else {
            setErrorMessage(response?.data?.message);
          }
        })
        .catch(error => {
          setErrorMessage('Could not perform Requested Operation')
        })
    }
  }


  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;

    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const checkValidation = () => {
    let errorMessage = "";
    for (let key in formValidationDetails) {
      if (!formValidationDetails[key]) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    return !Boolean(errorMessage);
  }

  const handleCheckBoxChange = (event) => {
    formDetails[event.target.name] = event.target.checked;
    if (event.target.name === "isAdmin") {
      formDetails.isDevView = event.target.checked;
      formDetails.isDevEdit = event.target.checked;
      formDetails.isStageView = event.target.checked;
      formDetails.isStageEdit = event.target.checked;
      formDetails.isProdEdit = event.target.checked;
      formDetails.isProdView = event.target.checked;
    } else if (event.target.name === "isDevEdit") {
      formDetails.isDevView = event.target.checked;
    } else if (event.target.name === "isStageEdit") {
      formDetails.isStageView = event.target.checked;
    } else if (event.target.name === "isProdEdit") {
      formDetails.isProdView = event.target.checked;
    }
    setFormDetails({ ...formDetails });
  }

  return (
    <div className={css.container}>
      <div className={css.header}>Update User</div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Name<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "name", "")}
              keyName="name"
              label="Name"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Name"
              isRequired={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label htmlFor="isAdmin" className={css.label}>Admin(User Page)</label>
          </div>
          <div>
            <Checkbox
              value={formDetails.isAdmin}
              checked={formDetails.isAdmin}
              name="isAdmin"
              color="primary"
              id="isAdmin"
              onChange={(e) => handleCheckBoxChange(e)}
            />
          </div>
        </div>
      </div>

      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Email<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "email", "")}
              keyName="email"
              label="Email"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Email"
              isRequired={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>
        <div className={css.column}></div>
      </div>

      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label htmlFor="isDevView" className={css.label}>Dev View Access</label>
          </div>
          <div>
            <Checkbox
              value={formDetails.isDevView}
              checked={formDetails.isDevView}
              name="isDevView"
              id="isDevView"
              color="primary"
              onChange={(e) => handleCheckBoxChange(e)}
              disabled={formDetails.isDevEdit || formDetails.isAdmin}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label htmlFor="isDevEdit" className={css.label}>Dev Edit Access</label>
          </div>
          <div>
            <Checkbox
              value={formDetails.isDevEdit}
              checked={formDetails.isDevEdit}
              name="isDevEdit"
              color="primary"
              id="isDevEdit"
              onChange={(e) => handleCheckBoxChange(e)}
              disabled={formDetails.isAdmin}
            />
          </div>
        </div>
      </div>

      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label htmlFor="isStageView" className={css.label}>Stage View Access</label>
          </div>
          <div>
            <Checkbox
              value={formDetails.isStageView}
              checked={formDetails.isStageView}
              name="isStageView"
              color="primary"
              id="isStageView"
              onChange={(e) => handleCheckBoxChange(e)}
              disabled={formDetails.isStageEdit || formDetails.isAdmin}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label htmlFor="isStageEdit" className={css.label}>Stage Edit Access</label>
          </div>
          <div>
            <Checkbox
              value={formDetails.isStageEdit}
              checked={formDetails.isStageEdit}
              name="isStageEdit"
              id="isStageEdit"
              color="primary"
              onChange={(e) => handleCheckBoxChange(e)}
              disabled={formDetails.isAdmin}
            />
          </div>
        </div>
      </div>

      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label} htmlFor="isProdView">Prod View Access</label>
          </div>
          <div>
            <Checkbox
              value={formDetails.isProdView}
              checked={formDetails.isProdView}
              name="isProdView"
              id="isProdView"
              color="primary"
              onChange={(e) => handleCheckBoxChange(e)}
              disabled={formDetails.isProdEdit || formDetails.isAdmin}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label htmlFor="isProdEdit" className={css.label}>Prod Edit Access</label>
          </div>
          <div>
            <Checkbox
              value={formDetails.isProdEdit}
              checked={formDetails.isProdEdit}
              name="isProdEdit"
              color="primary"
              id="isProdEdit"
              onChange={(e) => handleCheckBoxChange(e)}
              disabled={formDetails.isAdmin}
            />
          </div>
        </div>
      </div>

      <p className={css.errorMessage}>{errorMessage}</p>
      <div className={css.footer}>
        <div className={css.buttonContainer}>
          <SecondaryButton text={"Discard"} onClick={onModalClose} />
        </div>
        <div className={css.buttonContainer}>
          <PrimaryButton text="Save" onClick={handleSaveClick} />
        </div>
      </div>
    </div>
  )
}

export default UpdateUserModal;