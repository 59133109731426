import React from "react";

import css from "./VariantWeightBar.module.css";

const VariantWeightBar = (props) => {
  const { variants } = props;
  const colors = ["#757575", "#FF5733", "#3399FF", "#33FF6E", "#9933FF", "#FFD633", "#FF8C33", "#33FFD6"];

  const totalWeight = variants.reduce((acc, variant) => acc + (+variant.weight), 0);
  const percentages = variants.map((variant) => ((+variant.weight / totalWeight) * 100).toFixed(2));

  return (
    <div className={css.variantsWeight}>
      <div className={css.headerText}>
        Variant's Weight:
      </div>
      <div className={css.descriptionText}>
        To maintain the integrity of the experiment, <b>you won't be able to change the weights once the experiment starts running.</b>
      </div>
      <div className={css.bar}>
        <div className={css.barContainer}>
          {
            variants.map((variant, index) => {
              return (
                <div className={css.barContent} style={{ width: `${percentages[index]}%`, backgroundColor: colors?.[index] ?? "" }}>{percentages[index]}%</div>
              )
            })
          }
        </div>
        <div className={css.barDescription}>
          {
            variants.map((variant, index) => {
              return (
                <div className={css.labelContainer} style={{ width: `${percentages[index]}%` }}>
                  <span className={css.colorDot} style={{ backgroundColor: colors?.[index] ?? "" }}></span>
                  <span className={css.label} >{variant.name}</span>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default VariantWeightBar;