import React from "react";

import css from "./AddPanelSidebar.module.css";

import Topbar from "./../../components/TopBar";
import Sidebar from "./../../components/Sidebar";

export default function AddPanelSidebar(props) {
  return (
    <div>
      <Topbar shouldShowStage={props.shouldShowStage} />
      <div className={css.container}>
        <div
          className={`is-hidden-touch ${css.sidebar}`}
          style={{ height: "100%", width: "15%", display: "flex" }}
        >
          <Sidebar />
        </div>
        <div className={css.contentContainer}>{props.childComponent}</div>
      </div>
    </div>
  );
}
