import React from "react";

import { default as MaterialUiAvatar } from '@mui/material/Avatar';

import lodash from "lodash";

const Avatar = (props) => {
  const { profileUrl, firstName, lastName, displayName } = props;

  const getAvatarName = (firstName, lastName, displayName) => {
    if (firstName || lastName) {
      return firstName.charAt(0) + (lastName ? lastName.charAt(0) : "");
    } else if (displayName) {
      return (
        lodash.first(displayName.split(" ")).charAt(0) +
        (displayName.split(" ")[1] ? displayName.split(" ")[1].charAt(0) : "")
      );
    }
    return "";
  };

  return profileUrl ? (
    <MaterialUiAvatar
      variant={lodash.get(props, "variant", "circle")}
      style={{ height: props.height, width: props.width }}
      src={profileUrl}
    />
  ) : (
    <MaterialUiAvatar style={{ height: props.height, width: props.width, fontSize: props.fontSize }}>
      <p>{getAvatarName(firstName, lastName, displayName)}</p>
    </MaterialUiAvatar>
  );
};

export default Avatar;
