import React, { useEffect, useState } from "react";

import css from "./appVersionSection.module.css";

import lodash from "lodash";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import InputText from "../../../components/InputText";
import PrimaryButton from "../../../components/PrimaryButton";
import { ExperimentFormMode, ExperimentStatus } from "../../../services/constants";
import VariantSection from "../variantSection";

const AppVersionSection = (props) => {
  const { mode, formDetails, handleInputChange, shouldApplyValidation, handleIsValid, handleAddTreatment, handleDeleteTreatment, handleAppVersionInputChange, handleAddAppVersion } = props;
  const MAX_APP_VERSION_SIZE = 5;
  const [shouldOpenAppVersionSections, setShouldOpenAppVersionSections] = useState([]);
  
  useEffect(() => {
    for (let i = 0; i < formDetails.appVersions.length; i++) {
      if (shouldOpenAppVersionSections.length <= i) {
        shouldOpenAppVersionSections[i] = mode == ExperimentFormMode.create;
      }
    }
    setShouldOpenAppVersionSections([...shouldOpenAppVersionSections]);
  }, [formDetails.appVersions.length])

  const handleExpandClick = (index) => {
    shouldOpenAppVersionSections[index] = !shouldOpenAppVersionSections[index];
    setShouldOpenAppVersionSections([...shouldOpenAppVersionSections]);
  }

  return (
    <div className={css.appVersionSectionContainer}>
      <div className={css.descriptionText}>
        App Version - Variant Section
      </div>
      {formDetails.appVersions.map((appVersionDetails, index) => {
        return (
          <div className={css.container} key={index}>
            <div className={css.controlSectionContainer} >
              <div className={css.header}>
                <div className={css.appVersionContainer}>
                  <div className={css.labelContainer}>
                    <label className={css.label}>App Version: </label>
                  </div>
                  <div>
                    <InputText
                      value={lodash.get(appVersionDetails, "appVersion", "")}
                      keyName="appVersion"
                      label="App Version"
                      onInputChange={(e) => handleAppVersionInputChange(e, index)}
                      placeholder="Version"
                      isValid={(isValid, property) => handleIsValid(isValid, property)}
                      disabled={[ExperimentFormMode.view].includes(mode)}
                    />
                  </div>
                </div>
                <span className={css.expandIcon} onClick={() => handleExpandClick(index)}>
                  {shouldOpenAppVersionSections[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </span>
              </div>
              {
                shouldOpenAppVersionSections[index] && (
                  <div className={css.rowContainer}>
                    <VariantSection
                      mode={mode}
                      formDetails={formDetails}
                      shouldApplyValidation={shouldApplyValidation}
                      appVersionIndex={index}
                      handleInputChange={(event, variantIndex) => handleInputChange(event, index, variantIndex)}
                      handleIsValid={handleIsValid}
                      handleAddTreatment={() => handleAddTreatment(index)}
                      handleDeleteTreatment={(variantIndex) => handleDeleteTreatment(index, variantIndex)}
                    />
                  </div>
                )
              }
            </div>
          </div>
        )
      })}
      <div className={css.buttonContainer}>
        {(formDetails.appVersions.length < MAX_APP_VERSION_SIZE) && <PrimaryButton text="Add App Version" onClick={handleAddAppVersion} disabled={[ExperimentFormMode.view].includes(mode)} />}
      </div>
    </div>
  )
}

export default AppVersionSection;