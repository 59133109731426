import React, { useEffect, useState } from "react";

import css from "./ExperimentVariableDropdown.module.css";

import { CircularProgress } from '@mui/material';

import Snackbar from "../Snackbar";

import { makeGetAPICAll } from "../../services/api";
import { API, StatusCodeResult } from "../../services/constants";

const ExperimentVariableDropdown = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [experimentVariableData, setExperimentVariableData] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    validateInput(props.value);
    getExperimentVariables();
  }, []);

  useEffect(() => {
    if (props.shouldRefresh) {
      getExperimentVariables();
    }
  }, [props.shouldRefresh]);

  useEffect(() => {
    validateInput(props.value);
  }, [props.required]);

  const getExperimentVariables = () => {
    setIsLoading(true);
    makeGetAPICAll(API.getExperimentVariables).then((response) => {
      setIsLoading(false);
      if (response.code === StatusCodeResult.success) {
        setExperimentVariableData(response.data);
      }
    }).catch((error) => setResponseMessage(error))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (event) => {
    validateInput(event.target.value);
    props.onInputChange(event);
  };

  const validateInput = (value) => {
    props.required && props.isValid(Boolean(value), props.keyName);
  };

  const onCreateExperimentClick = () => {
    if(!props.disabled) {
      props.onCreateExperimentClick();
    }
  }

  return (
    <div
      className={`select ${props.value || !props.required ? "" : "is-danger"} ${css.dropdownContainer
        }`}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (<>
        <select
          className={`${css.dropdown} is-fullwidth`}
          name={props.keyName}
          value={+props.value}
          onChange={handleInputChange}
          disabled={props.disabled}
        >
          <option value="">Select Experiment Variable</option>
          {experimentVariableData &&
            experimentVariableData.map((data) => {
              return (
                <option value={data.id} key={data.id} variableDetails={JSON.stringify(data)}>
                  {data.name}
                </option>
              );
            })}
        </select>
        {props.onCreateExperimentClick && <div className={props.disabled ? css.disabled : css.link} onClick={onCreateExperimentClick}>Create Experiment Variable</div>}
        {(!props.value && props.required) && <p className="help is-danger">Select Experiment Variable</p>}
      </>
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default ExperimentVariableDropdown;
