import React, { useState, useEffect } from "react";

import css from "./TreatmentValues.module.css";

import _ from "underscore";

import { CircularProgress } from '@mui/material';

import { makeDeleteAPICall, makeGetAPICAll } from "../../services/api";
import { API } from "../../services/constants";

import PrimaryButton from "../../components/PrimaryButton";
import Snackbar from "./../../components/Snackbar";
import Modal from "../../components/Modal";
import AlertDialog from "../../components/AlertDialog";

import ExperimentVariableTable from "../../pageComponents/experimentVariableTable";
import CreateExperimentVariableModal from "../../pageComponents/createExperimentVariableModal";
import UpdateExperimentVariableModal from "../../pageComponents/updateExperimentVariableModal";
import TreatmentValueTable from "../../pageComponents/treatmentValueTable";
import CreateTreatmentValueModal from "../../pageComponents/createTreatmentValueModal";
import UpdateTreatmentValueModal from "../../pageComponents/updateTreatmentValueModal";
import AccessChecker from "../../components/AccessChecker";

const TreatmentValues = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowTreatmentValueModal, setShouldShowTreatmentValueModal] = useState(false);
  const [shouldShowEditTreatmentValueModal, setShouldShowEditTreatmentValueModal] = useState(false);
  const [treatmentValues, setTreatmentValues] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [shouldShowAlertDialog, setShouldShowAlertDialog] = useState(false);
  const [treatmentValueId, setTreatmentValueId] = useState(null);
  const [selectedTreatmentValue, setSelectedTreatmentValue] = useState({});

  useEffect(() => {
    getTreatmentValues();
  }, []);

  const getTreatmentValues = (filters = { pageNumber: 1 }) => {
    setIsLoading(true);
    makeGetAPICAll(API.getTreatmentValue, filters).then((response) => {
      setIsLoading(false);
      if (response.success) {
        setTreatmentValues(response.data);
      } else {
        setResponseMessage(response.data || response.message);
      }
    });
  };

  const handleEditClick = (treatmentValue) => {
    setShouldShowEditTreatmentValueModal(true);
    setSelectedTreatmentValue(treatmentValue);
  }

  const handleDeleteClick = (treatmentValue) => {
    if(treatmentValue.experimentName && treatmentValue.experimentIsActive) {
      setResponseMessage("Deletion of the treatment is not allowed as it is currently assigned to an experiment.");
      return;
    }
    setTreatmentValueId(treatmentValue.id);
    setShouldShowAlertDialog(true);
  }

  const deleteTreatmentValue = (id) => {
    setIsLoading(true);
    makeDeleteAPICall(API.deleteTreatmentValue, { id }).then((response) => {
      setIsLoading(false);
      if (response.success) {
        getTreatmentValues();
      } else {
        setResponseMessage(response.data || response.message);
      }
    });
  }

  const createTreatmentValue = () => {
    setShouldShowTreatmentValueModal(true);
  }

  const handleModalClose = (isSuccess) => {
    setShouldShowTreatmentValueModal(false);
    if (isSuccess === true) {
      getTreatmentValues();
    }
  }

  const handleDialogAction = (dialogAction) => {
    setShouldShowAlertDialog(false);
    if (dialogAction) {
      deleteTreatmentValue(treatmentValueId);
    }
  }

  const handleEditModalClose = (isSuccess) => {
    setShouldShowEditTreatmentValueModal(false);
    if(isSuccess === true) {
      getTreatmentValues();
    }
  }

  return (
    <div className={css.treatmentsContainer}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className={css.header}>
            <div className={css.headerText}>Treatment Value</div>
            <div className={css.buttonContainer}>
              <AccessChecker onClick={() => createTreatmentValue()}>
                <PrimaryButton text="Create Treatment Value"  />
              </AccessChecker>
            </div>
          </div>
          <div className={css.tableContainer}>
            <TreatmentValueTable
              treatmentValues={treatmentValues}
              onEditClick={(treatmentValueDetails) =>
                handleEditClick(treatmentValueDetails)
              }
              onDeleteClick={(treatment) =>
                handleDeleteClick(treatment)
              }
            />
          </div>
        </>
      )}
      {shouldShowTreatmentValueModal && (
        <Modal
          isModalOpen={shouldShowTreatmentValueModal}
          handleClose={(isSuccess) => handleModalClose(isSuccess)}
        >
          <CreateTreatmentValueModal
            onModalClose={(isSuccess) => handleModalClose(isSuccess)}
          />
        </Modal>
      )}
      {shouldShowEditTreatmentValueModal && (
        <Modal
          isModalOpen={shouldShowEditTreatmentValueModal}
          handleClose={(isSuccess) => handleEditModalClose(isSuccess)}
        >
          <UpdateTreatmentValueModal
            onModalClose={(isSuccess) => handleEditModalClose(isSuccess)}
            treatmentValueDetails={selectedTreatmentValue}
          />
        </Modal>
      )}
      {shouldShowAlertDialog && (
        <AlertDialog
          showAlert={shouldShowAlertDialog}
          onDialogActionButtonClick={(dialogAction) =>
            handleDialogAction(dialogAction)
          }
          title={"Are you sure you want to delete this treatment?"}
          disagreeText="No"
          agreeText="Yes"
        />
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default TreatmentValues;