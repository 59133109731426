import React, { useState, useEffect } from "react";

import css from "./TextArea.module.css";

export default function TextArea(props) {
  //state variable for store error message
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    validateInput(props.value);
  }, [props.value, props.isRequired]);

  //when input change this method call
  const handleInputChange = (event) => {
    validateInput(event.target.value);
    if (props.value || (event.target.value && event.target.value.trim())) {
      props.onInputChange(event);
    }
  };

  const validateInput = (value) => {
    let isValid = true;
    let message = "";
    if (props.isRequired && (!value || !value.trim())) {
      message = "Enter " + props.label;
      isValid = false;
    }
    if (props.maxLength && props.value.length >= props.maxLength) {
      message = props.label + " contains " + props.maxLength + " characters";
      isValid = false;
    }
    props.isValid && props.isValid(isValid, props.keyName);
    setErrorMessage(message);
  };

  return (
    <>
      <div className="control" style={{ width: "100%" }}>
        <textarea
          name={props.keyName}
          className={errorMessage ? "textarea is-danger" : "textarea"}
          placeholder={props.placeholder}
          value={props.value ? props.value : ""}
          onChange={(e) => handleInputChange(e)}
          rows={props.rows}
          disabled={props.disabled}
          autoFocus={props.autoFocus}
          onBlur={(event) => props.onFocusLost && props.onFocusLost(event)}
          maxLength={props.maxLength}
        ></textarea>
        <p className="help is-danger">{errorMessage}</p>
      </div>
    </>
  );
}
