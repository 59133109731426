import React, { useState } from "react";

import css from "./mainSection.module.css";

import lodash from "lodash";

import InputText from "../../../components/InputText";
import TextArea from "../../../components/TextArea";
import InputNumber from "../../../components/InputNumber";
import StatusDropdown from "../../../components/StatusDropdown";
import ExperimentVariableDropdown from "../../../components/ExperimentVariableDropdown";
import CreateExperimentVariableModal from "../../createExperimentVariableModal";
import Modal from "../../../components/Modal";
import TreatmentValueDropdown from "../../../components/TreatmentValueDropdown";

import { ExperimentFormMode, ExperimentStatus } from "../../../services/constants";

const MainSection = (props) => {
  const { mode } = props;
  const [shouldShowExperimentVariableModal, setShouldShowExperimentVariableModal] = useState(false);
  const [shouldRefreshExperimentVariable, setShouldRefreshExperimentVariable] = useState(false);

  const { formDetails, handleInputChange, shouldApplyValidation, handleIsValid } = props;

  const audienceOptions = [{ text: "Select Audience", value: "" }, { text: "All Users", value: "ALL_USERS" }]

  const handleModalClose = (isSuccess) => {
    setShouldShowExperimentVariableModal(false);
    if (isSuccess) {
      setShouldRefreshExperimentVariable(isSuccess);
    }
  }

  const handleCreateExperimentClick = () => {
    setShouldRefreshExperimentVariable(false);
    setShouldShowExperimentVariableModal(true);
  }

  return (
    <div className={css.mainSectionContainer}>
      <div className={css.descriptionText}>
        Main Section
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Name<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "name", "")}
              keyName="name"
              label="Name"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Name"
              isRequired={shouldApplyValidation}
              disabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Description<span className={css.required}>*</span></label>
          </div>
          <div>
            <TextArea
              value={lodash.get(formDetails, "description", "")}
              keyName="description"
              type="description"
              label="description"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="description"
              isRequired={shouldApplyValidation}
              disabled={[ExperimentFormMode.view].includes(mode)}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              rows={2}
            />
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Ramp(%)<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputNumber
              value={lodash.get(formDetails, "ramp", "")}
              keyName="ramp"
              label="Ramp"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Ramp"
              isRequired={shouldApplyValidation}
              disabled={true}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              maxValue={100}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Variable<span className={css.required}>*</span></label>
          </div>
          <div>
            <ExperimentVariableDropdown
              onInputChange={handleInputChange}
              value={lodash.get(formDetails, "experimentVariableId", "")}
              keyName="experimentVariableId"
              shouldRefresh={shouldRefreshExperimentVariable}
              required={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              onCreateExperimentClick={handleCreateExperimentClick}
              disabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
            />
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>All User Treatment</label>
          </div>
          <div>
            <TreatmentValueDropdown
              onInputChange={(e) => handleInputChange(e, 0)}
              value={lodash.get(formDetails, "nonRampUserTreatmentValueId", "")}
              keyName="nonRampUserTreatmentValueId"
              required={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              disabled={[ExperimentFormMode.view].includes(mode)}
              variableId={formDetails.experimentVariableId}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Audience<span className={css.required}>*</span></label>
          </div>
          <div>
            <StatusDropdown
              options={audienceOptions}
              onInputChange={handleInputChange}
              value={lodash.get(formDetails, "audience", "ALL_USERS")}
              keyName="audience"
              disabled={true}
            />
          </div>
        </div>
      </div>

      {shouldShowExperimentVariableModal && (
        <Modal
          isModalOpen={shouldShowExperimentVariableModal}
          handleClose={(event) => handleModalClose(false)}
        >
          <CreateExperimentVariableModal
            onModalClose={(isSuccess) => handleModalClose(isSuccess)}
          />
        </Modal>
      )}
    </div>
  )
}

export default MainSection;