import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import css from "./SignIn.module.css";

import lodash from "lodash";

import { useUser } from './../../context/userContext';

import { API, CookiesKey, Validation, WebsitePageLinks } from "../../services/constants";
import PrimaryButton from "../../components/PrimaryButton";
import InputText from "../../components/InputText";

import { makePostAPICAll } from "../../services/api";

import { getSignInRequest } from "../../model/signIn";
import { getCookie, setCookie } from "../../utils/Cookies";
import TopBar from "../../components/TopBar";


const SignIn = (props) => {
  const [shouldApplyValidation, setShouldApplyValidation] = useState();
  const [formDetails, setFormDetails] = useState({ email: "", password: "" });
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");

  const { setUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie(CookiesKey.adminToken)) {
      navigate(WebsitePageLinks.experiment);
    }
  }, []);

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  };

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const onSignInButtonClicked = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const saveFormData = () => {
    makePostAPICAll(API.signIn, getSignInRequest(formDetails))
      .then(response => {
        if (response.success) {
          setCookie(CookiesKey.adminToken, response.data.token);
          delete response.data.token;
          setUser(response.data);
          navigate(WebsitePageLinks.experimentVariable)
        } else {
          setResponseError(response?.data?.message);
        }
      })
      .catch(error => {
        setResponseError('Could not perform Requested Operation')
      })
  }

  return (
    <div className={css.container}>
      <TopBar />
      <div className={css.signIn}>
        <title>Sign In</title>
        <div className={css.signInSection}>
          <p className={css.signInText}>Sign In</p>

          <div className={`field ${css.row}`}>
            <div className={css.column}>
              <div className={css.labelContainer}>
                <label className={css.label}>Email: </label>
              </div>
              <div>
                <InputText
                  value={lodash.get(formDetails, "email", "")}
                  keyName="email"
                  label="Email"
                  onInputChange={(e) => handleInputChange(e)}
                  placeholder="Email Address"
                  pattern={
                    shouldApplyValidation && Validation.emailReg
                  }
                  isValid={(isValid, property) => handleIsValid(isValid, property)}
                />
              </div>
            </div>
            <div className={css.column}>
              <div className={css.labelContainer}>
                <label className={css.label}>Password: </label>
              </div>
              <div>
                <InputText
                  value={lodash.get(formDetails, "password", "")}
                  keyName="password"
                  type="password"
                  label="Password"
                  onInputChange={(e) => handleInputChange(e)}
                  placeholder="Password"
                  isRequired={shouldApplyValidation}
                  isValid={(isValid, property) => handleIsValid(isValid, property)}
                  minValue={shouldApplyValidation ? 1 : undefined}
                />
              </div>
            </div>
          </div>

          <div className={css.signInButton}>
            <PrimaryButton text={"Sign In"} onClick={onSignInButtonClicked} />
          </div>
          <p className={css.errorText}>{responseError}</p>
        </div>
      </div>
    </div>
  )
};

export default SignIn;
