// Set a value in localStorage with optional expiration time in minutes
function setLocalStorage(key, value, expirationMinutes = null) {
    const now = new Date();
    const item = {
      value,
      expiration: expirationMinutes ? now.getTime() + expirationMinutes * 60000 : null,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  
  // Get a value from localStorage and check for expiration
  function getLocalStorage(key) {
    const item = JSON.parse(localStorage.getItem(key));
    if (item && (!item.expiration || new Date().getTime() <= item.expiration)) {
      return item.value;
    } else {
      localStorage.removeItem(key); // Remove expired item
      return null;
    }
  }
  
  // Remove a value from localStorage
  function removeLocalStorage(key) {
    localStorage.removeItem(key);
  }
  
  // Clear all items from localStorage
  function clearLocalStorage() {
    localStorage.clear();
  }
  
  export { setLocalStorage, getLocalStorage, removeLocalStorage, clearLocalStorage };
  