import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import css from "./Sidebar.module.css";
import { WebsitePageLinks, WebsitePages } from "../../services/constants";
import { useUser } from "../../context/userContext"; 


const Sidebar = (props) => {
  const { userData } = useUser();
  const location = useLocation();

  const games = [
    {
      name: 'Castle Royal',
      features: [WebsitePages.experimentVariable, WebsitePages.treatmentValue, WebsitePages.experiment],
    }
  ];

  const getActiveItem = () => {
    if (WebsitePages) {
      for (const item in WebsitePages) {
        if (location.pathname.startsWith(WebsitePages[item].path)) {
          return WebsitePages[item].path;
        }
      }
    }

    return '';
  };

  const checkIsAdmin = () => {
    return !userData || userData.isAdmin;
  }

  return (
    <div className={css.sidebar}>
      <ul className={css.gameList}>
        {games.map((game, index) => (
          <li key={index} className={css.gameItem}>
            <span className={css.gameName}>{game.name}</span>
            <ul className={css.featureList}>
              {game.features.map((feature, featureIndex) => (
                <li key={featureIndex} className={css.featureItem}>
                  <Link
                    to={feature.path}
                    className={`${css.featureLink} ${getActiveItem() === feature.path ? css.active : ''}`}
                  >
                    {feature.title}
                  </Link>
                </li>
              ))}
              {checkIsAdmin() ? <li className={css.featureItem}>
                <Link
                  to={WebsitePageLinks.user}
                  className={`${css.featureLink} ${getActiveItem() === WebsitePageLinks.user ? css.active : ''}`}
                >
                  {WebsitePages.user.title}
                </Link>
              </li> : null}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
