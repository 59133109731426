import moment from "moment";
import { ExperimentStatus, VariantTypes } from "../services/constants";
import { getEpochTimeFromDateTime } from "../utils/DateFormat";

export const getCreateExperimentRequest = (formDetails) => {
    return {
        name: formDetails.name,
        description: formDetails.description,
        slug: getSlug(formDetails.name),
        status: ExperimentStatus.created,
        audience: formDetails.audience,
        startDate: getEpochTimeFromDateTime(formDetails.startDate, formDetails.startTime),
        endDate: formDetails.endDate ? getEpochTimeFromDateTime(formDetails.endDate, formDetails.endTime) : null,
        variableId: formDetails.experimentVariableId,
        ramp: formDetails.ramp,
        nonRampUserTreatmentValueId: formDetails.nonRampUserTreatmentValueId,
        variants: getVariantsPayload(formDetails)
    }
}

export const updateExperimentRequest = (changedData, serverExperimentDetails) => {
    let request = {
        ...(changedData.description && { description: changedData.description }),
        ...(changedData.experimentVariableId && { variableId: changedData.experimentVariableId }),
        ...(changedData.ramp && { ramp: changedData.ramp }),
        ...(changedData.appVersions && { variants: getUpdateVariantsPayload(changedData) }),
        ...(changedData.nonRampUserTreatmentValueId && { nonRampUserTreatmentValueId: changedData.nonRampUserTreatmentValueId }),
    };
    if (changedData.name) {
        request.slug = getSlug(changedData.name);
        request.name = changedData.name;
    }
    if (changedData.startDate && changedData.startTime) {
        request.startDate = getEpochTimeFromDateTime(changedData.startDate, changedData.startTime);
    }
    if (changedData.endDate && changedData.endTime) {
        request.endDate = getEpochTimeFromDateTime(changedData.endDate, changedData.endTime);
    }
    request.hasVersionChanged = checkVersionChanged(changedData, serverExperimentDetails);
    return request;
}

export const getMoveExperimentRequest = (formDetails) => {
    return {
        targetStage: formDetails.targetStage,
        sourceStage: formDetails.sourceStage,
        name: formDetails.name,
        description: formDetails.description,
        slug: getSlug(formDetails.name),
        status: ExperimentStatus.created,
        audience: formDetails.audience,
        startDate: getEpochTimeFromDateTime(formDetails.startDate, formDetails.startTime),
        endDate: formDetails.endDate ? getEpochTimeFromDateTime(formDetails.endDate, formDetails.endTime) : null,
        variableId: formDetails.experimentVariableId,
        ramp: formDetails.ramp,
        nonRampUserTreatmentValueId: formDetails.nonRampUserTreatmentValueId,
        variants: getVariantsPayload(formDetails)
    }
}

export const getMoveRemoteConfigRequest = (formDetails) => {
    return {
        targetStage: formDetails.targetStage,
        sourceStage: formDetails.sourceStage,
        name: formDetails.name,
        description: formDetails.description,
        slug: getSlug(formDetails.name),
        status: ExperimentStatus.completed,
        audience: formDetails.audience,
        variableId: formDetails.experimentVariableId,
        ramp: formDetails.ramp,
        nonRampUserTreatmentValueId: formDetails.nonRampUserTreatmentValueId,
    }
}

export const getCreateRemoteConfigRequest = (formDetails) => {
    return {
        name: formDetails.name,
        description: formDetails.description,
        slug: getSlug(formDetails.name),
        status: ExperimentStatus.completed,
        audience: formDetails.audience,
        variableId: formDetails.experimentVariableId,
        ramp: formDetails.ramp,
        nonRampUserTreatmentValueId: formDetails.nonRampUserTreatmentValueId,
    }
}

const checkVersionChanged = (changedData, serverExperimentDetails) => {
    if (serverExperimentDetails.status === ExperimentStatus.created || serverExperimentDetails.status === ExperimentStatus.paused) return false;
    else if (changedData.ramp) return true;
    else if (changedData.nonRampUserTreatmentValueId) return true;
    else if (changedData.appVersions && checkIsAppVersionChanged(changedData)) return true;
    else if (changedData.endDate && getExperimentStatus(serverExperimentDetails.status, getEpochTimeFromDateTime(changedData.endDate, changedData.endTime)) !== serverExperimentDetails.status) return true;
    return false;
}

const getExperimentStatus = (endDate) => {
    if (endDate < Date.now()) return ExperimentStatus.completed;
    return ExperimentStatus.started;
}

const getSlug = (name) => {
    return name.toLowerCase().replace(/ /g, '_');
}

const getVariantsPayload = (formDetails) => {
    let variantsPayload = [];
    for (let appVersionDetails of formDetails.appVersions) {
        appVersionDetails.variants.map((variant, index) => {
            variantsPayload.push({
                name: variant.name,
                type: index === 0 ? VariantTypes.control : VariantTypes.treatment,
                weight: variant.weight,
                description: variant.description ?? "",
                allowedUsers: variant.allowList ? variant.allowList.split(",") : [],
                treatmentValueId: variant.treatmentValueId,
                appVersion: appVersionDetails.appVersion?.trim() ?? ""
            });
        });
    }
    return variantsPayload;
}

const getUpdateVariantsPayload = (formDetails) => {
    let variantsPayload = [];
    for (let appVersionDetails of formDetails.appVersions) {
        appVersionDetails.variants.map((variant, index) => {
            variantsPayload.push(variant);
        });
    }
    return variantsPayload;
}

const checkIsAppVersionChanged = (changedData) => {
    let isAppVersionChanged = false;
    for (let appVersionDetails of changedData.appVersions) {
        isAppVersionChanged = isAppVersionChanged || (appVersionDetails.variants.filter(variant => variant.treatmentValueId || variant.weight || variant.appVersion).length > 0);
    }
    return isAppVersionChanged;
}