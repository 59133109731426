import React, { useState } from 'react';

import { useUser } from '../../context/userContext';

import AlertDialog from '../AlertDialog';
import { Stage } from '../../services/constants';
import AppGrants from '../../services/appGrants';

function AccessChecker(props) {
  const [shouldShowAlertDialog, setShouldShowAlertDialog] = useState(false);
  const { userData } = useUser();

  const handleClick = (event) => {
    event.stopPropagation();
    if (!props.disabled) {
      if (userData?.access?.split(",")?.includes(getUserGrant(userData.stage))) {
        props.onClick(event);
      } else {
        setShouldShowAlertDialog(true);
      }
    }
  };

  const getUserGrant = (stage) => {
    let userGrant = "";
    switch (stage) {
      case Stage.development:
        userGrant = AppGrants.EDIT_DEVELOPMENT
        break;
      case Stage.staging:
        userGrant = AppGrants.EDIT_STAGING
        break;
      case Stage.production:
        userGrant = AppGrants.EDIT_PRODUCTION
        break;
      default:
        userGrant = AppGrants.EDIT_DEVELOPMENT
        break;
    }
    return userGrant;
  }

  function handleDialog(dialogAction, event) {
    setShouldShowAlertDialog(false);
    event.stopPropagation();
  }

  return (
    <>
      <span onClick={handleClick}>{props.children}</span>
      <AlertDialog
        showAlert={shouldShowAlertDialog}
        title='The account you signed in with does not have permission to perform this action'
        agreeText='OK'
        onDialogActionButtonClick={handleDialog}
      />
    </>
  );
}

export default AccessChecker;
