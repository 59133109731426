import { Stage } from "../services/constants";

export const getNextStage = (stage) => {
    let nextStage = "";
    switch (stage) {
        case Stage.development:
            nextStage = Stage.staging
            break;
        case Stage.staging:
            nextStage = Stage.production
        default:
            break;
    }
    return nextStage;
}