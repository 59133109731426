import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import css from "./User.module.css";

import _ from "underscore";

import { CircularProgress } from '@mui/material';

import { makeDeleteAPICall, makeGetAPICAll } from "../../services/api";
import { API } from "../../services/constants";

import Snackbar from "./../../components/Snackbar";
import PrimaryButton from "../../components/PrimaryButton";
import AlertDialog from "../../components/AlertDialog";
import Modal from "../../components/Modal";

import UserTable from "../../pageComponents/userTable";
import CreateUserModal from "../../pageComponents/createUserModal";
import UpdateUserModal from "../../pageComponents/updateUserModal";

const User = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowCreateUserModal, setShouldShowCreateUserModal] = useState(false);
  const [shouldShowEditUserModal, setShouldShowEditUserModal] = useState(false);
  const [users, setUsers] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [selectedUser, setSelectedUser] = useState({});
  const [alertDialogDetails, setAlertDialogDetails] = useState({ shouldShow: false, title: '', handler: null });


  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = (filters = { pageNumber: 1 }) => {
    setIsLoading(true);
    makeGetAPICAll(API.getUsers, filters).then((response) => {
      setIsLoading(false);
      if (response.success) {
        setUsers(response.data);
      } else {
        setResponseMessage(response.data || response.message);
      }
    });
  };

  const hanldeDeleteExperiment = (id) => {
    selectedUser.id = id;
    setSelectedUser({ ...selectedUser });
    setAlertDialogDetails({ shouldShow: true, header: "Are you sure you want to delete this user?", handler: handleDeleteUserModalClose })
  }

  const handleDeleteUserModalClose = (isSuccess) => {
    setAlertDialogDetails({ shouldShow: false, header: "", handler: null })
    if (isSuccess === true) {
      deleteUser();
    }
  }

  const deleteUser = () => {
    setIsLoading(true);
    makeDeleteAPICall(API.deleteUser, { id: selectedUser.id }).then((response) => {
      setIsLoading(false);
      if (response.success) {
        getUsers();
      } else {
        setResponseMessage(response?.data?.message ?? "Error");
      }
    });
  }

  const handleEditClick = (userDetails) => {
    setSelectedUser(userDetails);
    setShouldShowEditUserModal(true);
  }

  const handleModalClose = (isSuccess) => {
    setShouldShowCreateUserModal(false);
    if (isSuccess === true) {
      getUsers();
    }
  }

  const handleEditModalClose = (isSuccess) => {
    setShouldShowEditUserModal(false);
    if(isSuccess === true) {
      getUsers();
    }
  }

  return (
    <div className={css.userContainer}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className={css.header}>
            <div className={css.headerText}>Users</div>
            <div className={css.container}>
              <div className={css.buttonContainer}>
                <PrimaryButton text="Create User" onClick={() => setShouldShowCreateUserModal(true)} />
              </div>
            </div>
          </div>
          <div className={css.tableContainer}>
            <UserTable
              users={users}
              onEditClick={(user) => handleEditClick(user)}
              onDeleteClick={(id) => hanldeDeleteExperiment(id)}
            />
          </div>
        </>
      )}
       {shouldShowCreateUserModal && (
        <Modal
          isModalOpen={shouldShowCreateUserModal}
          handleClose={(isSuccess) => handleModalClose(isSuccess)}
        >
          <CreateUserModal
            onModalClose={(isSuccess) => handleModalClose(isSuccess)}
          />
        </Modal>
      )}
      {shouldShowEditUserModal && (
        <Modal
          isModalOpen={shouldShowEditUserModal}
          handleClose={(isSuccess) => handleEditModalClose(isSuccess)}
        >
          <UpdateUserModal
            onModalClose={(isSuccess) => handleEditModalClose(isSuccess)}
            userDetails={selectedUser}
          />
        </Modal>
      )}
      {alertDialogDetails.shouldShow && (
        <AlertDialog
          showAlert={alertDialogDetails.shouldShow}
          onDialogActionButtonClick={(dialogAction) =>
            alertDialogDetails.handler(dialogAction)
          }
          title={alertDialogDetails.header}
          disagreeText="No"
          agreeText="Yes"
        />
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default User;