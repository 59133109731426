import React from "react";

import css from "./experimentTable.module.css";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import Tooltip from '@mui/material/Tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import _ from "underscore";
import { DateFormats, ExperimentStatus, LocalStorageKey } from "../../services/constants";
import AccessChecker from "../../components/AccessChecker";
import moment from "moment";
import { getNextStage } from "../../utils/stage";
import { getLocalStorage } from "../../services/localStorage";

const ExperimentTable = (props) => {
  const { experiments, onMoveExperimentClick } = props;

  const onDeleteExperiment = (discount) => {
    props.onDeleteExperiment(discount);
  };

  return (
    <div className={css.tableContainer}>
      <table className="table is-striped is-fullwidth">
        <thead className={css.tableHeaderContainer}>
          <tr>
            <th className={css.tableHeader}>Name</th>
            <th className={css.tableHeader}>Status</th>
            <th className={css.tableHeader}>Ramp</th>
            <th className={css.tableHeader}>Remote Config</th>
            <th className={css.tableHeader}>Start Date</th>
            {/* <th className={css.tableHeader}>End Date</th> */}
            <th className={css.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(experiments) &&
            experiments.map((experiment, index) => {
              return (
                <tr key={index}>
                  <td className={css.tableData}>{experiment.name}</td>
                  <td className={css.tableData}>{experiment.status}</td>
                  <td className={css.tableData}>{experiment.ramp}</td>
                  <td className={css.tableData}>{experiment.isRemoteConfig ? "True" : "False"}</td>
                  <td className={css.tableData}>{experiment.isRemoteConfig ? "-" : moment(experiment.startDate).format(DateFormats.primary)}</td>
                  {/* <td className={css.tableData}>{experiment.endDate ? moment(experiment.endDate).format(DateFormats.primary) : "-"}</td> */}
                  <td className={css.tableData}>
                    <div className={css.buttonContainer}>
                      {!experiment.isRemoteConfig &&
                        <AccessChecker onClick={() => props.onPauseExperiment(experiment.id, experiment.status)}>
                          <div className={css.button}>
                            {experiment.status === ExperimentStatus.paused ? <PlayArrowIcon /> : <PauseCircleIcon />}
                          </div>
                        </AccessChecker>
                      }
                      <div className={css.button}>
                        <RemoveRedEyeIcon onClick={() => props.onViewClick(experiment.id, experiment.isRemoteConfig)} />
                      </div>
                      {!experiment.isRemoteConfig &&
                        <AccessChecker onClick={() => props.onEditExperiment(experiment.id)}>
                          <div className={css.button}>
                            <EditIcon />
                          </div>
                        </AccessChecker>
                      }
                      <AccessChecker onClick={() => onDeleteExperiment(experiment.id)}>
                        <div className={css.button}>
                          <DeleteIcon />
                        </div>
                      </AccessChecker>
                      <AccessChecker onClick={() => onMoveExperimentClick(experiment)}>
                        <div className={css.button}>
                          <Tooltip
                            title={
                              <span style={{ whiteSpace: "pre-line", fontSize: "14px" }}>
                                Move to {getNextStage(getLocalStorage(LocalStorageKey.stage)).toLowerCase()}
                              </span>
                            }
                          >
                            <DriveFileMoveIcon />
                          </Tooltip>
                        </div>
                      </AccessChecker>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ExperimentTable;
