import React from "react";

import css from "./PageNotFound.module.css";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";

const PageNotFound = (props) => {
    return (
        <h1> 
            404!
        </h1>  
    )
}

export default PageNotFound;