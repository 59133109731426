export const getCreateExperimentVariableRequest = (formDetails) => {
    return {
        name: formDetails.name,
        valueType: formDetails.valueType,
        slug: formDetails.slug,
        appVersion: formDetails.appVersion,
        schema: formDetails.schema,
    }
}

export const updateExperimentVariableRequest = (formDetails) => {
    return {
        valueType: formDetails.valueType,
        appVersion: formDetails.appVersion,
        version: formDetails.version,
        schema: formDetails.schema,
        hasExperimentVersionChanged: formDetails.hasExperimentVersionChanged
    }
}