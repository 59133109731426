import React from "react";

import css from "./experimentVariable.module.css";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import _ from "underscore";
import AccessChecker from "../../components/AccessChecker";

const ExperimentVariableTable = (props) => {
  const { experimentVariables } = props;

  const onEditExperiment = (experimentVariable) => {
    props.onEditClick(experimentVariable);
  };

  const onDeleteExperiment = (experimentVariable) => {
    props.onDeleteClick(experimentVariable);
  };

  return (
    <div className={css.tableContainer}>
      <table className="table is-striped is-fullwidth">
        <thead className={css.tableHeaderContainer}>
          <tr>
            <th className={css.tableHeader}>Name</th>
            <th className={css.tableHeader}>Value Type</th>
            <th className={css.tableHeader}>Experiment Name</th>
            <th className={css.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(experimentVariables) &&
            experimentVariables.map((experimentVariable, index) => {
              return (
                <tr key={index}>
                  <td className={css.tableData}>{experimentVariable.name}</td>
                  <td className={css.tableData}>{experimentVariable.valueType}</td>
                  <td className={css.tableData}>{experimentVariable.experimentName ?? "-"}</td>
                  <td className={css.tableData}>
                    <div className={css.buttonContainer}>
                      <AccessChecker onClick={() => onEditExperiment(experimentVariable)}>
                        <div className={css.button}>
                          <EditIcon />
                        </div>
                      </AccessChecker>
                      <AccessChecker onClick={() => onDeleteExperiment(experimentVariable)}>
                        <div className={css.button}>
                          <DeleteIcon />
                        </div>
                      </AccessChecker>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ExperimentVariableTable;
