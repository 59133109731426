import React from "react";

import css from "./userTable.module.css";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import _ from "underscore";

const UserTable = (props) => {
  const { users } = props;

  const onEditUser = (user) => {
    props.onEditClick(user);
  };

  const onDeleteUser = (id) => {
    props.onDeleteClick(id);
  };

  return (
    <div className={css.tableContainer}>
      <table className="table is-striped is-fullwidth">
        <thead className={css.tableHeaderContainer}>
          <tr>
            <th className={css.tableHeader}>Name</th>
            <th className={css.tableHeader}>Email</th>
            <th className={css.tableHeader}>Admin</th>
            <th className={css.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(users) &&
            users.map((user, index) => {
              return (
                <tr key={index}>
                  <td className={css.tableData}>{user.name}</td>
                  <td className={css.tableData}>{user.email}</td>
                  <td className={css.tableData}>{user.isAdmin ? "Yes": "No"}</td>
                  <td className={css.tableData}>
                    <div className={css.buttonContainer}>
                      <div
                        className={css.button}
                        onClick={() => onEditUser(user)}
                      >
                        <EditIcon />
                      </div>
                      <div
                        className={css.button}
                        onClick={() => onDeleteUser(user.id)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
