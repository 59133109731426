import React from "react";

import { useUser } from './../../context/userContext';

const Dashboard = (props) => {
    const { userData } = useUser();

    return (
        <h2>Welcome, {userData?.name}!</h2>
    )
}

export default Dashboard;