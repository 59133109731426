import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from './../../context/userContext';

import css from "./TopBar.module.css";

import Logo from "../../assets/logo.png";

import {
  CookiesKey,
  WebsitePageLinks,
  LocalStorageKey,
  Stage
} from "../../services/constants";

import { deleteCookie, getCookie } from "./../../utils/Cookies";
import Avatar from "../Avatar";
import StatusDropdown from "../StatusDropdown";
import AppGrants from "../../services/appGrants";
import { getLocalStorage, setLocalStorage } from "../../services/localStorage";

const TopBar = (props) => {
  const { shouldShowStage } = props;
  let devOption = { text: "Development", value: "DEVELOPMENT" }, stageOption = { text: "Staging", value: "STAGING" }, prodOption = { text: "Production", value: "PRODUCTION" };
  const navigate = useNavigate()
  const { userData, setUser } = useUser();
  const [stageOptions, setStageOptions] = useState([]);
  const [stage, setStage] = useState("");

  useEffect(() => {
    let storedStage = getLocalStorage(LocalStorageKey.stage);
    if (!storedStage) {
      setLocalStorage(LocalStorageKey.stage, Stage.development);
      storedStage = Stage.development;
    }
    setStage(storedStage);
  }, []);

  useEffect(() => {
    let grants = userData?.access?.split(",") ?? [];
    let copyStageOptions = []
    if (grants.includes(AppGrants.VIEW_DEVELOPMENT)) {
      copyStageOptions.push(devOption);
    }
    if (grants.includes(AppGrants.VIEW_STAGING)) {
      copyStageOptions.push(stageOption);
    }
    if (grants.includes(AppGrants.VIEW_PRODUCTION)) {
      copyStageOptions.push(prodOption);
    }
    setStageOptions([...copyStageOptions]);
    if (!userData?.stage && copyStageOptions.length > 1) {
      userData.stage = copyStageOptions[0].value;
      setUser({ ...userData });
    }
  }, [userData?.access])

  const handleLogoutClick = () => {
    deleteCookie(CookiesKey.adminToken);
    setUser({});
    navigate(WebsitePageLinks.signIn);
  }

  const checkShowLogout = () => {
    return Boolean(getCookie(CookiesKey.adminToken));
  }

  const handleInputChange = (event) => {
    setStage(event.target.value);
    setLocalStorage(LocalStorageKey.stage, event.target.value);
    userData.stage = event.target.value;
    setUser({ ...userData });
    window.location.reload();
  }

  return (
    <div className={css.topBarContainer}>
      <div className={css.topBar}>
        <img className={css.logo} src={Logo} />
      </div>
      {shouldShowStage && <div className={css.dropdownContainer}>
        <StatusDropdown
          options={stageOptions}
          onInputChange={handleInputChange}
          value={stage}
          keyName="stage"
        />
      </div>}
      <div className={css.buttonContainer}>
        <div className={css.container}>
          <div className={css.avatar}>
            {userData?.name && <Avatar displayName={userData.name} />}
          </div>
          {checkShowLogout() &&
            <button className={css.button} onClick={handleLogoutClick}>
              Logout
            </button>
          }
        </div>
      </div>

    </div>
  );
};

export default TopBar;
