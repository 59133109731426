import { TreatmentValueTypes } from "../services/constants"

export const getExperimentTreatmentValue = (formDetails) => {
    return {
        name: formDetails.name,
        value: formDetails.valueType === TreatmentValueTypes.json ?JSON.stringify(JSON.parse(formDetails.value)): formDetails.value,
        variableId: +formDetails.experimentVariableId
    }
}

export const updateExperimentTreatmentValue = (formDetails) => {
    return {
        value: formDetails.valueType === TreatmentValueTypes.json ?JSON.stringify(JSON.parse(formDetails.value)): formDetails.value,
        version: formDetails.version,
        variableId: +formDetails.experimentVariableId,
        hasExperimentVersionChanged: formDetails.hasExperimentVersionChanged,
    }
}