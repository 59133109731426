import React, { useEffect, useState } from "react";

import css from "./variantSection.module.css";

import lodash from "lodash";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/Delete";

import InputText from "../../../components/InputText";
import TextArea from "../../../components/TextArea";
import TreatmentValueDropdown from "../../../components/TreatmentValueDropdown";
import Modal from "../../../components/Modal";
import PrimaryButton from "../../../components/PrimaryButton";
import CreateTreatmentValueModal from "../../createTreatmentValueModal";
import { ExperimentFormMode, ExperimentStatus } from "../../../services/constants";
import InputNumber from "../../../components/InputNumber";
import VariantWeightBar from "../../VariantWeightBar";

const VariantSection = (props) => {
  const { mode, formDetails, handleInputChange, shouldApplyValidation, handleIsValid, handleAddTreatment, handleDeleteTreatment, appVersionIndex } = props;
  const MAX_TREATMENT_SIZE = 8;
  const [shouldOpenControlSection, setShouldOpenControlSection] = useState(true);
  const [shouldOpenTreatmentSections, setShouldOpenTreatmentSections] = useState([]);
  const [shouldRefreshTreatmentValue, setShouldTreatmentValue] = useState(false);
  const [shouldShowTreatmentValueModal, setShouldShowTreatmentValueModal] = useState(false);

  useEffect(() => {
    for (let i = 1; i < formDetails.appVersions[appVersionIndex].variants.length; i++) {
      if (shouldOpenTreatmentSections.length <= i - 1) {
        shouldOpenTreatmentSections[i - 1] = true;
      }
    }
    setShouldOpenTreatmentSections([...shouldOpenTreatmentSections]);
  }, [formDetails.appVersions[appVersionIndex].variants.length])

  const handleControlExpandClick = () => {
    setShouldOpenControlSection(!shouldOpenControlSection);
  }

  const handleTreatmentExpandClick = (index) => {
    shouldOpenTreatmentSections[index] = !shouldOpenTreatmentSections[index];
    setShouldOpenTreatmentSections([...shouldOpenTreatmentSections]);
  }

  const handleModalClose = (isSuccess) => {
    setShouldShowTreatmentValueModal(false);
    if(isSuccess) {
      setShouldTreatmentValue(isSuccess);
    }
  }

  const handleTreatmentValueCreate = () => {
    setShouldTreatmentValue(false);
    setShouldShowTreatmentValueModal(true);
  }

  const handleDeleteClick = (index) => {
    shouldOpenTreatmentSections.splice(index - 1, 1);
    setShouldOpenTreatmentSections([...shouldOpenTreatmentSections]);
    handleDeleteTreatment(index);
  }

  return (
    <div className={css.variantSectionContainer}>
      <div className={css.descriptionText}>
        Variant Section
      </div>

      <div className={css.container}>
        <div className={css.controlSectionContainer} >
          <div className={css.header}>
            <p className={css.descriptionText}>Control</p>
            <span className={css.expandIcon} onClick={handleControlExpandClick}>
              {shouldOpenControlSection ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </span>
          </div>
          {
            shouldOpenControlSection && (
              <div className={css.rowContainer}>
                <div className={`field ${css.row}`}>
                  <div className={css.column}>
                    <div className={css.labelContainer}>
                      <label className={css.label}>Name<span className={css.required}>*</span></label>
                    </div>
                    <div>
                      <InputText
                        value={lodash.get(formDetails, `appVersions[${appVersionIndex}].variants[0].name`, "")}
                        keyName="name"
                        label="Name"
                        onInputChange={(e) => handleInputChange(e, 0)}
                        placeholder="Name"
                        isRequired={shouldApplyValidation}
                        isValid={(isValid, property) => handleIsValid(isValid, property)}
                        disabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
                      />
                    </div>
                  </div>
                  <div className={css.column}>
                    <div className={css.labelContainer}>
                      <label className={css.label}>Description</label>
                    </div>
                    <div>
                      <TextArea
                        value={lodash.get(formDetails, `appVersions[${appVersionIndex}].variants[0].description`, "")}
                        keyName="description"
                        type="description"
                        label="description"
                        onInputChange={(e) => handleInputChange(e, 0)}
                        placeholder="description"
                        rows={2}
                        disabled={[ExperimentFormMode.view].includes(mode)}
                      />
                    </div>
                  </div>
                </div>
                <div className={`field ${css.row}`}>
                  <div className={css.column}>
                    <div className={css.labelContainer}>
                      <label className={css.label}>Weight<span className={css.required}>*</span></label>
                    </div>
                    <div>
                      <InputNumber
                        value={lodash.get(formDetails, `appVersions[${appVersionIndex}].variants[0].weight`, "")}
                        keyName="weight"
                        label="Weight"
                        onInputChange={(e) => handleInputChange(e, 0)}
                        placeholder="Weight"
                        isRequired={shouldApplyValidation}
                        isValid={(isValid, property) => handleIsValid(isValid, property)}
                        disabled={[ExperimentFormMode.view].includes(mode)}
                      />
                    </div>
                  </div>
                  <div className={css.column}>
                    <div className={css.labelContainer}>
                      <label className={css.label}>Treatment</label>
                    </div>
                    <div>
                      <TreatmentValueDropdown
                        onInputChange={(e) => handleInputChange(e, 0)}
                        value={lodash.get(formDetails, `appVersions[${appVersionIndex}].variants[0].treatmentValueId`, "")}
                        keyName="treatmentValueId"
                        shouldRefresh={shouldRefreshTreatmentValue}
                        required={shouldApplyValidation}
                        isValid={(isValid, property) => handleIsValid(isValid, property)}
                        onCreateTreatmentValue={handleTreatmentValueCreate}
                        disabled={[ExperimentFormMode.view].includes(mode)}
                        variableId={formDetails.experimentVariableId}
                      />
                    </div>
                  </div>
                </div>
                <div className={`field ${css.row}`}>
                  <div className={css.column}>
                    <div className={css.labelContainer}>
                      <label className={css.label}>Allow List</label>
                    </div>
                    <div>
                      <InputText
                        value={lodash.get(formDetails, `appVersions[${appVersionIndex}].variants[0].allowList`, "")}
                        keyName="allowList"
                        label="Allow List"
                        onInputChange={(e) => handleInputChange(e, 0)}
                        placeholder="Allow List"
                        isValid={(isValid, property) => handleIsValid(isValid, property)}
                        disabled={[ExperimentFormMode.view].includes(mode)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        {
          formDetails.appVersions[appVersionIndex].variants.map((variant, index) => {
            if (index === 0) return;
            return (
              <div className={css.controlSectionContainer} key={index}>
                <div className={css.header}>
                  <p className={css.descriptionText}>Treatment Variant</p>
                  <span className={css.expandIcon}>
                    {!([ExperimentFormMode.view].includes(mode) || formDetails.status && formDetails.status !== ExperimentStatus.created) && <DeleteIcon onClick={() => handleDeleteClick(index)} />}
                    <span onClick={() => handleTreatmentExpandClick(index - 1)}>
                      {shouldOpenTreatmentSections[index - 1] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </span>
                  </span>
                </div>
                {
                  shouldOpenTreatmentSections[index - 1] && (
                    <div className={css.rowContainer}>
                      <div className={`field ${css.row}`}>
                        <div className={css.column}>
                          <div className={css.labelContainer}>
                            <label className={css.label}>Name<span className={css.required}>*</span></label>
                          </div>
                          <div>
                            <InputText
                              value={lodash.get(variant, "name", "")}
                              keyName="name"
                              label="Name"
                              onInputChange={(e) => handleInputChange(e, index)}
                              placeholder="Name"
                              isRequired={shouldApplyValidation}
                              isValid={(isValid, property) => handleIsValid(isValid, property)}
                              disabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created && variant.id)}
                            />
                          </div>
                        </div>
                        <div className={css.column}>
                          <div className={css.labelContainer}>
                            <label className={css.label}>Description</label>
                          </div>
                          <div>
                            <TextArea
                              value={lodash.get(variant, "description", "")}
                              keyName="description"
                              type="description"
                              label="description"
                              onInputChange={(e) => handleInputChange(e, index)}
                              placeholder="description"
                              rows={2}
                              disabled={[ExperimentFormMode.view].includes(mode)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`field ${css.row}`}>
                        <div className={css.column}>
                          <div className={css.labelContainer}>
                            <label className={css.label}>Weight<span className={css.required}>*</span></label>
                          </div>
                          <div>
                            <InputNumber
                              value={lodash.get(variant, "weight", "")}
                              keyName="weight"
                              label="Weight"
                              onInputChange={(e) => handleInputChange(e, index)}
                              placeholder="Weight"
                              isRequired={shouldApplyValidation}
                              isValid={(isValid, property) => handleIsValid(isValid, property)}
                              disabled={[ExperimentFormMode.view].includes(mode)}
                            />
                          </div>
                        </div>
                        <div className={css.column}>
                          <div className={css.labelContainer}>
                            <label className={css.label}>Treatment</label>
                          </div>
                          <div>
                            <TreatmentValueDropdown
                              onInputChange={(e) => handleInputChange(e, index)}
                              value={lodash.get(variant, "treatmentValueId", "")}
                              keyName="treatmentValueId"
                              shouldRefresh={shouldRefreshTreatmentValue}
                              onCreateTreatmentValue={handleTreatmentValueCreate}
                              required={shouldApplyValidation}
                              isValid={(isValid, property) => handleIsValid(isValid, property)}
                              disabled={[ExperimentFormMode.view].includes(mode)}
                              variableId={formDetails.experimentVariableId}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`field ${css.row}`}>
                        <div className={css.column}>
                          <div className={css.labelContainer}>
                            <label className={css.label}>Allow List</label>
                          </div>
                          <div>
                            <InputText
                              value={lodash.get(variant, "allowList", "")}
                              keyName="allowList"
                              label="Allow List"
                              onInputChange={(e) => handleInputChange(e, index)}
                              placeholder="Allow List"
                              isValid={(isValid, property) => handleIsValid(isValid, property)}
                              disabled={[ExperimentFormMode.view].includes(mode)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          })
        }
        <div className={css.buttonContainer}>
          {!formDetails.appVersions[appVersionIndex].variants.length < MAX_TREATMENT_SIZE && <PrimaryButton text="Add Treatment" onClick={handleAddTreatment} disabled={[ExperimentFormMode.view].includes(mode)} />}
        </div>

        <VariantWeightBar variants={formDetails.appVersions[appVersionIndex].variants} />
      </div>
      {shouldShowTreatmentValueModal && (
        <Modal
          isModalOpen={shouldShowTreatmentValueModal}
          handleClose={(event) => handleModalClose(false)}
        >
          <CreateTreatmentValueModal
            onModalClose={(isSuccess) => handleModalClose(isSuccess)}
          />
        </Modal>
      )}
    </div>
  )
}

export default VariantSection;