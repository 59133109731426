import AppGrants from "../services/appGrants";

export const createUserRequest = (formDetails) => {
    return {
        name: formDetails.name,
        email: formDetails.email,
        password: formDetails.password,
        isAdmin: formDetails.isAdmin,
        access: getAccess(formDetails),
    }
}

export const updateUserRequest = (formDetails) => {
    return {
        name: formDetails.name,
        email: formDetails.email,
        password: formDetails.password,
        isAdmin: Boolean(formDetails.isAdmin),
        access: getAccess(formDetails),
    }
}

const getAccess = (formDetails) => {
    let access = [];
    if(formDetails.isDevView) {
        access.push(AppGrants.VIEW_DEVELOPMENT);
    }
    if(formDetails.isDevEdit) {
        access.push(AppGrants.EDIT_DEVELOPMENT);
    }
    if(formDetails.isStageView) {
        access.push(AppGrants.VIEW_STAGING);
    }
    if(formDetails.isStageEdit) {
        access.push(AppGrants.EDIT_STAGING);
    }
    if(formDetails.isProdView) {
        access.push(AppGrants.VIEW_PRODUCTION);
    }
    if(formDetails.isProdEdit) {
        access.push(AppGrants.EDIT_PRODUCTION);
    }
    return access.join(",");
}