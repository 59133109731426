import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import css from "./CreateExperiment.module.css";

import Header from "../../pageComponents/createExperiment/header";
import MainSection from "../../pageComponents/createExperiment/mainSection";
import VariantSection from "../../pageComponents/createExperiment/variantSection";

import Snackbar from "../../components/Snackbar";

import { API, ExperimentFormMode, WebsitePageLinks } from "../../services/constants";
import { makePostAPICAll } from "../../services/api";

import { getCreateExperimentRequest } from "../../model/experiment";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import AppVersionSection from "../../pageComponents/createExperiment/appVersionSection";

import { compareVersions, isValidVersion } from "../../utils/Validator";

const CreateExperiment = (props) => {
  let navigate = useNavigate();
  const headerText = "Add an Experiment";
  const descriptionText = "Your A/B test needs atleast one control and one variant";

  const [formDetails, setFormDetails] = useState({ appVersions: [{ variants: [{ name: "Control", weight: 1 }, { name: "Variant A", weight: 1 }] }] });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);


  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.experiment)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleVariantInputChange = (event, appVersionIndex, variantIndex) => {
    formDetails.appVersions[appVersionIndex].variants[variantIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleAppVersionInputChange = (event, appVersionIndex) => {
    formDetails.appVersions[appVersionIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });

  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const handleAddTreatment = (appVersionIndex) => {
    formDetails.appVersions[appVersionIndex].variants.push({ weight: 1, name: "Variant " + String.fromCharCode(65 + formDetails.appVersions[appVersionIndex].variants.length - 1) });
    setFormDetails({ ...formDetails });
  }

  const handleAddAppVersion = () => {
    formDetails.appVersions.push(({ variants: [{ name: "Control", weight: 1 }, { name: "Variant A", weight: 1 }] }))
    setFormDetails({ ...formDetails });
  }

  const handleDeleteTreatment = (appVersionIndex, variantIndex) => {
    formDetails.appVersions[appVersionIndex].variants.splice(variantIndex, 1);
    setFormDetails({ ...formDetails });
  }

  const saveFormData = () => {
    if (validateForm()) {
      setIsSaveClicked(true);
      makePostAPICAll(API.createExperiment, getCreateExperimentRequest(formDetails))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.experiment)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        })
        .finally(() => {
          setIsSaveClicked(false);
        })
    }
  }

  const validateForm = () => {
    let previousAppVersion = -1;
    for (let appVersionDetails of formDetails.appVersions) {
      if (!isValidVersion(appVersionDetails.appVersion)) {
        setResponseError(`Enter valid app version`);
        return false;
      }
      if (compareVersions(previousAppVersion, appVersionDetails.appVersion) > 0) {
        setResponseError(`App version should be in accending order`);
        return false;
      }
      if (appVersionDetails.variants.length < 2) {
        setResponseError(`Your A/B test needs atleast one control and one variant for app version ${appVersionDetails.appVersion}`);
        return false;
      }
      previousAppVersion = appVersionDetails.appVersion;
    }

    let errorMessage = "";
    for (let key in formValidationDetails) {
      if (formValidationDetails[key] === false) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} handleSaveClick={handleSaveClick} handleDiscardClick={handleDiscardClick} />
      <MainSection mode={ExperimentFormMode.create} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <AppVersionSection
        mode={ExperimentFormMode.create}
        formDetails={formDetails}
        shouldApplyValidation={shouldApplyValidation}
        handleInputChange={handleVariantInputChange}
        handleIsValid={handleIsValid}
        handleAddTreatment={handleAddTreatment}
        handleDeleteTreatment={handleDeleteTreatment}
        handleAppVersionInputChange={handleAppVersionInputChange}
        handleAddAppVersion={handleAddAppVersion}
      />
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
    </div>
  )
}

export default CreateExperiment;