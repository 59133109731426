import React, { useState, useEffect } from "react";

import css from "./timePicker.module.css";

import TextField from '@mui/material/TextField';

export default function TimePicker(props) {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    validateInput(props.time);
  }, [props.isRequired, props.time, props.min]);

  //when input change this method call
  const handleInputChange = (e) => {
    validateInput(e.target.value);
    props.onInputChange(e);
  };

  const validateInput = (value) => {
    setErrorMessage("");
    props.isValid && props.isValid(true, props.keyName);
    if (props.isRequired) {
      if (!value) {
        setErrorMessage("Enter " + props.label);
        props.isValid && props.isValid(false, props.keyName);
      } else if (props.min && value < props.min) {
        setErrorMessage(`Enter ${props.label} ${props.min} or greater`);
        props.isValid && props.isValid(false, props.keyName);
      }
    }
  };

  return (
    <div className={css.timePickerContainer}>
      <TextField
        id="time"
        type="time"
        value={props.time ? props.time : ""}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: props.step, // 5 min
          className: css.timePicker
        }}
        name={props.keyName}
        style={{ width: "100%" }}
        onChange={(e) => handleInputChange(e)}
        disabled={props.isDisabled}
      />
      <p className="help is-danger">{errorMessage}</p>
    </div>
  );
}
