import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./MoveExperiment.module.css";

import MainSection from "../../pageComponents/createExperiment/mainSection";
import VariantSection from "../../pageComponents/createExperiment/variantSection";
import Header from "../../pageComponents/createExperiment/header";

import { API, ExperimentFormMode, ExperimentStatus, LocalStorageKey, WebsitePageLinks } from "../../services/constants";
import { makeGetAPICAll, makePostAPICAll } from "../../services/api";
import { getLocalStorage } from "../../services/localStorage";
import { getNextStage } from "../../utils/stage";

import { getMoveExperimentRequest } from "../../model/experiment";
import PrimaryButton from "../../components/PrimaryButton";
import Snackbar from "../../components/Snackbar";
import SecondaryButton from "../../components/SecondaryButton";
import { compareVersions, isValidVersion } from "../../utils/Validator";
import AppVersionSection from "../../pageComponents/createExperiment/appVersionSection";

const MoveExperiment = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  const mode = ExperimentFormMode.move, currentStage = getLocalStorage(LocalStorageKey.stage);
  const headerText = "Move an Experiment", descriptionText = "When moving an experiment, the system will automatically create variables and treatments. If you've created them manually, please delete them before proceeding with the move.";

  const [experimentDetails, setExperimentDetails] = useState({});
  const [formDetails, setFormDetails] = useState({ appVersions: [{ variants: [{}, {}] }] });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);


  useEffect(() => {
    if (params.id) {
      getExperimentById(params.id);
    }
  }, [params.id])

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const getExperimentById = (experimentId) => {
    makeGetAPICAll(API.getById(experimentId))
      .then(response => {
        if (response.success) {
          setExperimentDetails({ ...response.data, appVersions: getAppVersions(response.data) });
          setFormDetails(getFormDetails({ ...response.data }));
        } else {
          setResponseError(response?.data?.message);
        }
      })
      .catch(error => {
        setResponseError('Could not perform Requested Operation')
      })
  }

  const getFormDetails = (experimentDetails) => {
    delete experimentDetails.startDate; delete experimentDetails.endDate; //for move experiment they have to select start date and end date.
    return {
      ...experimentDetails,
      experimentVariableId: experimentDetails.variableId,
      status: ExperimentStatus.created,
      appVersions: getAppVersions(experimentDetails),
    }
  }

  const getAppVersions = (experimentDetails) => {
    let appVersions = [], appVersionVariantsMapping = {};
    for (let variant of experimentDetails.variants) {
      if(!variant.appVersion) variant.appVersion = "";
      if (!appVersionVariantsMapping[variant.appVersion]) {
        appVersionVariantsMapping[variant.appVersion] = [];
      }
      appVersionVariantsMapping[variant.appVersion].push({ ...variant });
    }

    for (let appVersion in appVersionVariantsMapping) {
      appVersions.push({
        appVersion: appVersion ?? "",
        variants: [...appVersionVariantsMapping[appVersion]]
      })
    }
    return [...appVersions];
  }

  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.experiment)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleVariantInputChange = (event, appVersionIndex, variantIndex) => {
    formDetails.appVersions[appVersionIndex].variants[variantIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleAppVersionInputChange = (event, appVersionIndex) => {
    formDetails.appVersions[appVersionIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });

  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const handleAddTreatment = (appVersionIndex) => {
    formDetails.appVersions[appVersionIndex].variants.push({ weight: 1, name: "Variant " + String.fromCharCode(65 + formDetails.appVersions[appVersionIndex].variants.length - 1) });
    setFormDetails({ ...formDetails });
  }

  const handleAddAppVersion = () => {
    formDetails.appVersions.push(({ appVersion: "", variants: [{ name: "Control", weight: 1 }, { name: "Variant A", weight: 1 }] }))
    setFormDetails({ ...formDetails });
  }

  const handleDeleteTreatment = (appVersionIndex, variantIndex) => {
    formDetails.appVersions[appVersionIndex].variants.splice(variantIndex, 1);
    setFormDetails({ ...formDetails });
  }

  const saveFormData = () => {
    if (validateForm()) {
      setIsSaveClicked(true);
      makePostAPICAll(API.moveExperiment, getMoveExperimentRequest({ ...formDetails, sourceStage: currentStage, targetStage: getNextStage(currentStage) }))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.experiment)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        }).finally(() => {
          setIsSaveClicked(false);
        })
    }
  }

  const validateForm = () => {
    let previousAppVersion = -1;
    for (let appVersionDetails of formDetails.appVersions) {
      if (!isValidVersion(appVersionDetails.appVersion)) {
        setResponseError(`Enter valid app version`);
        return false;
      }
      if (compareVersions(previousAppVersion, appVersionDetails.appVersion) > 0) {
        setResponseError(`App version should be in accending order`);
        return false;
      }
      if (appVersionDetails.variants.length < 2) {
        setResponseError(`Your A/B test needs atleast one control and one variant for app version ${appVersionDetails.appVersion}`);
        return false;
      }
      previousAppVersion = appVersionDetails.appVersion;
    }
    let errorMessage = "";
    for (let key in formValidationDetails) {
      if (formValidationDetails[key] === false) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} handleSaveClick={handleSaveClick} handleDiscardClick={handleDiscardClick} />
      <MainSection mode={mode} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <AppVersionSection
        mode={mode}
        formDetails={formDetails}
        shouldApplyValidation={shouldApplyValidation}
        handleInputChange={handleVariantInputChange}
        handleIsValid={handleIsValid}
        handleAddTreatment={handleAddTreatment}
        handleDeleteTreatment={handleDeleteTreatment}
        handleAppVersionInputChange={handleAppVersionInputChange}
        handleAddAppVersion={handleAddAppVersion}
      />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked || [ExperimentFormMode.view].includes(mode)} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
    </div>
  )
}

export default MoveExperiment;