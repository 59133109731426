import lodash from "lodash";

export const getValue = (data, key, defaultValue) => {
  let value = lodash.get(data, key);
  if (value === undefined || value === null) {
    return defaultValue;
  }
  return value;
};

export const isEmpty = (value) => {
  return lodash.isEmpty(value)
}

export const max = (array) => {
  return lodash.max(array)
}

export const set = (object, path, value) => {
  return lodash.set(object, path, value)
}

export const pick = (object, fields, condition) => {
  const filteredObject = lodash.pick(object, fields)
  if (condition) {
    return lodash.pickBy(filteredObject, condition)
  } else {
    return filteredObject
  }
}

export const isUndefined = (value) => {
  return lodash.isUndefined(value)
}

export const hasValue = (value) => {
  return value || value === false
}

export const popByPathAndIndex = (object, pathToArray, index) => {
  if (!pathToArray) {
    object.splice(index, 1)
    return object
  } else {
    const targetArray = getValue(object, pathToArray, [])
    targetArray.splice(index, 1)
    return set(object, pathToArray, targetArray)
  }
}

export const omit = (object, fields, condition) => {
  const filteredObject = lodash.omit(object, fields)
  if (condition) {
    return lodash.omitBy(filteredObject, condition)
  } else {
    return filteredObject
  }
}

export const isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const isValidVersion = (version) => {
  if(!version)  return true; //empty is allowed
  const parts = version.split('.');
  // Check if version has more than 2 dots or any part is not a number or greater than 99
  if (parts.length > 3) return false;
  return parts.every(part => !isNaN(part) && part >= 0 && part <= 99);
}

export const compareVersions = (version1, version2) => {
  if (version1 == version2) return 1; //not allowed
  if (version1 == null || version1 == -1) return -1;
  if (version2 == null) return 1; //version1 is not null and version 2 is null, not allowed
  if (version1.trim() == version2.trim()) return 1;
  const parts1 = version1.split('.');
  const parts2 = version2.split('.');
  const lastIndex1 = parts1.length - 1;
  const lastIndex2 = parts2.length - 1;

  parts1[lastIndex1] = parts1[lastIndex1].length === 1 ? Number(parts1[lastIndex1] + '0') : Number(parts1[lastIndex1]);
  parts2[lastIndex2] = parts2[lastIndex2].length === 1 ? Number(parts2[lastIndex2] + '0') : Number(parts2[lastIndex2]);
  const maxLength = Math.max(parts1.length, parts2.length);

  for (let i = 0; i < maxLength; i++) {
    const num1 = parts1[i] || 0; // Default to 0 if undefined
    const num2 = parts2[i] || 0; // Default to 0 if undefined

    if (num1 > num2) return 1;  // version1 is greater
    if (num1 < num2) return -1; // version2 is greater
  }

  // Versions are equal
  return 0;
}