import React, { useState, useEffect } from "react";

import css from "./ExperimentVariables.module.css";

import _ from "underscore";

import { CircularProgress } from '@mui/material';

import { makeDeleteAPICall, makeGetAPICAll } from "../../services/api";
import { API } from "../../services/constants";

import PrimaryButton from "../../components/PrimaryButton";
import Snackbar from "./../../components/Snackbar";
import Modal from "../../components/Modal";
import AlertDialog from "../../components/AlertDialog";

import ExperimentVariableTable from "../../pageComponents/experimentVariableTable";
import CreateExperimentVariableModal from "../../pageComponents/createExperimentVariableModal";
import UpdateExperimentVariableModal from "../../pageComponents/updateExperimentVariableModal";
import AccessChecker from "../../components/AccessChecker";

const ExperimentVariables = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowExperimentVariableModal, setShouldShowExperimentVariableModal] = useState(false);
  const [shouldShowEditExperimentVariableModal, setShouldShowEditExperimentVariableModal] = useState(false);
  const [experimentVariables, setExperimentVariables] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [shouldShowAlertDialog, setShouldShowAlertDialog] = useState(false);
  const [experimentVariableId, setExprimentVariableId] = useState(null);
  const [selectExperimentVariable, setSelectExperimentVariable] = useState({});

  useEffect(() => {
    getExperimentVariables();
  }, []);

  const getExperimentVariables = (filters = { pageNumber: 1 }) => {
    setIsLoading(true);
    makeGetAPICAll(API.getExperimentVariables, filters).then((response) => {
      setIsLoading(false);
      if (response.success) {
        setExperimentVariables(response.data);
      } else {
        setResponseMessage(response.data || response.message);
      }
    });
  };

  const handleEditClick = (experimentVariable) => {
    setShouldShowEditExperimentVariableModal(true);
    setSelectExperimentVariable(experimentVariable);
  }

  const handleDeleteClick = (experimentVariable) => {
    if(experimentVariable.experimentName && experimentVariable.experimentIsActive) {
      setResponseMessage("Deletion of the variable is not allowed as it is currently assigned to an experiment.");
      return;
    }
    if(experimentVariable.treatmentValueDetails) {
      setResponseMessage("Deletion of the variable is not allowed as it is currently assigned to an treatment.");
      return;
    }
    setExprimentVariableId(experimentVariable.id);
    setShouldShowAlertDialog(true);
  }

  const deleteExperimentVariable = (id) => {
    setIsLoading(true);
    makeDeleteAPICall(API.deleteExperimentVariable, { id }).then((response) => {
      setIsLoading(false);
      if (response.success) {
        getExperimentVariables();
      } else {
        setResponseMessage(response.data || response.message);
      }
    });
  }

  const createExperimentVariable = () => {
    setShouldShowExperimentVariableModal(true);
  }

  const handleModalClose = (isSuccess) => {
    setShouldShowExperimentVariableModal(false);
    if (isSuccess === true) {
      getExperimentVariables();
    }
  }

  const handleDialogAction = (dialogAction) => {
    setShouldShowAlertDialog(false);
    if (dialogAction) {
      deleteExperimentVariable(experimentVariableId);
    }
  }

  const handleEditModalClose = (isSuccess) => {
    setShouldShowEditExperimentVariableModal(false);
    if(isSuccess === true) {
      getExperimentVariables();
    }
  }

  return (
    <div className={css.experimentsContainer}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className={css.header}>
            <div className={css.headerText}>Experiment Variable</div>
            <div className={css.buttonContainer}>
              <AccessChecker onClick={() => createExperimentVariable()}>
                <PrimaryButton text="Create Experiment Variable" />
              </AccessChecker>
            </div>
          </div>
          <div className={css.tableContainer}>
            <ExperimentVariableTable
              experimentVariables={experimentVariables}
              onEditClick={(experimentVariableDetails) =>
                handleEditClick(experimentVariableDetails)
              }
              onDeleteClick={(experimentVariable) =>
                handleDeleteClick(experimentVariable)
              }
            />
          </div>
        </>
      )}
      {shouldShowExperimentVariableModal && (
        <Modal
          isModalOpen={shouldShowExperimentVariableModal}
          handleClose={(isSuccess) => handleModalClose(isSuccess)}
        >
          <CreateExperimentVariableModal
            onModalClose={(isSuccess) => handleModalClose(isSuccess)}
          />
        </Modal>
      )}
      {shouldShowEditExperimentVariableModal && (
        <Modal
          isModalOpen={shouldShowEditExperimentVariableModal}
          handleClose={(isSuccess) => handleEditModalClose(isSuccess)}
        >
          <UpdateExperimentVariableModal
            onModalClose={(isSuccess) => handleEditModalClose(isSuccess)}
            experimentVariableDetails={selectExperimentVariable}
          />
        </Modal>
      )}
      {shouldShowAlertDialog && (
        <AlertDialog
          showAlert={shouldShowAlertDialog}
          onDialogActionButtonClick={(dialogAction) =>
            handleDialogAction(dialogAction)
          }
          title={"Are you sure you want to delete this experiment variable?"}
          disagreeText="No"
          agreeText="Yes"
        />
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default ExperimentVariables;