import React, { useEffect, useState } from "react";

import css from "./updateExperimentVariableModal.module.css";

import lodash, { isEmpty } from "lodash";

import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import InputText from "../../components/InputText";
import StatusDropdown from "../../components/StatusDropdown";

import { API, ExperimentVariableValueTypes, TreatmentValueTypes } from "../../services/constants";
import { makePutAPICAll } from "../../services/api";

import { updateExperimentVariableRequest } from "../../model/experimentVariable";
import TextArea from "../../components/TextArea";
import { isJsonString } from "../../utils/Validator";

const UpdateExperimentVariableModal = (props) => {
  const { onModalClose, experimentVariableDetails } = props;
  const valueTypeOptions = [{ text: "Select Value Type", value: "" }, { text: "String", value: TreatmentValueTypes.string }, { text: "JSON", value: TreatmentValueTypes.json }];

  const [formDetails, setFormDetails] = useState({ name: experimentVariableDetails.name, valueType: experimentVariableDetails.valueType, appVersion: experimentVariableDetails.appVersion, schema: experimentVariableDetails.schema, slug: experimentVariableDetails.slug, appVersion: experimentVariableDetails.appVersion });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formValidationDetails, setFormValidationDetails] = useState({});

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const saveFormData = () => {
    let changedData = getChangedData();
    if (checkValidation(changedData)) {
      makePutAPICAll(API.updateExperimentVariable(experimentVariableDetails.id), updateExperimentVariableRequest({ ...formDetails, version: experimentVariableDetails.version, hasExperimentVersionChanged: getHasExperimentVersionChanged() }))
        .then(response => {
          if (response.success) {
            onModalClose(true)
          } else {
            setErrorMessage(response?.data?.message);
          }
        })
        .catch(error => {
          setErrorMessage('Could not perform Requested Operation')
        })
    }
  }

  const getHasExperimentVersionChanged = () => {
    let hasExperimentVersionChanged = false;
    if(formDetails.valueType !== experimentVariableDetails.valueType) {
      hasExperimentVersionChanged = true;
    }
    return hasExperimentVersionChanged;
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    if (event.target.value === ExperimentVariableValueTypes.control) {
      formDetails.constantValue = null;
    }
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const checkValidation = (changedData) => {
    let errorMessage = "";
    if (isEmpty(changedData)) {
      setErrorMessage("Nothing is changed!!");
      return false;
    }
    if (formDetails.schema) {
      if (!isJsonString(formDetails.schema)) {
        setErrorMessage("Invalid JSON for schema");
        return false;
      }
    }
    for (let key in formValidationDetails) {
      if (!formValidationDetails[key]) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    return !Boolean(errorMessage);
  }

  const getChangedData = () => {
    let changedData = {
      ...(formDetails.valueType !== experimentVariableDetails.valueType && { valueType: formDetails.valueType }),
      ...(formDetails.appVersion !== experimentVariableDetails.appVersion && { appVersion: formDetails.appVersion }),
      ...(formDetails.schema !== experimentVariableDetails.schema && { schema: formDetails.schema }),
    }
    return changedData
  }

  return (
    <div className={css.container}>
      <div className={css.header}>Update Experiment Variable</div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Name<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "name", "")}
              keyName="name"
              label="Name"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Name"
              isRequired={shouldApplyValidation}
              disabled={true}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Value Type<span className={css.required}>*</span></label>
          </div>
          <div>
            <StatusDropdown
              options={valueTypeOptions}
              onInputChange={handleInputChange}
              value={lodash.get(formDetails, "valueType", "")}
              keyName="valueType"
              required={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>App Version</label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "appVersion", "")}
              keyName="appVersion"
              label="App Version"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="App Version"
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Slug(For App):</label>
          </div>
          <div>
            <label className={css.slugLabel}>{formDetails.slug}</label>
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Schema</label>
          </div>
          <div>
            <TextArea
              value={lodash.get(formDetails, "schema", "")}
              keyName="schema"
              label="Schema"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Schema"
              disabled={formDetails.valueType === TreatmentValueTypes.string}
              rows={5}
            />
          </div>
        </div>
      </div>
      <p className={css.errorMessage}>{errorMessage}</p>
      <div className={css.footer}>
        <div className={css.buttonContainer}>
          <SecondaryButton text={"Discard"} onClick={onModalClose} />
        </div>
        <div className={css.buttonContainer}>
          <PrimaryButton text="Save" onClick={handleSaveClick} />
        </div>
      </div>
    </div>
  )
}

export default UpdateExperimentVariableModal;